import React from 'react'
import { useTranslation } from 'react-i18next'


const ReadySection = () => {
  const { t } = useTranslation();

  return (
    <section className="home-ready">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-9">
              <p>{t('h-s10-h2')}</p>
              <p>
              {t('h-s10-p')}
              </p>
            </div>
            <div className="col-xl-3">
            <a class="btn btn-solid-blue" href="https://my.tastyqr.nl/auth/signup">{t('h-s10-btn')}</a>
            </div>
            
          </div>
        </div>
      </section>
  )
}

export default ReadySection