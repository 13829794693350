import React, {useState, useEffect} from "react";
import IternalHeroBlock from "./IternalHeroBlock";

import stick from "../assets/images/single-tick.svg";
import close from "../assets/images/close.svg";
import dd from "../assets/images/dropdown.svg";
import "./Price.css";

import Faq from "./Faq";
import { Link } from "react-router-dom";
import Whatsapp from "../assets/images/whatsapp.svg";

import {motion} from 'framer-motion'
import { useTranslation } from 'react-i18next';


const Price = () => {
  useEffect(()=>{
    document.title = "Prijzen - TastyQR";
  }, [])

  const [offer, setOffer] = useState(false);
  const [starterMonthly , setStarterMonthly] = useState(79)
  const [standardMonthly , setStandardMonthly] = useState(119)
  const [professionalMonthly , setProffesionalMonthly] = useState(199)
  
  const [starterAnnually , setStarterAnnually] = useState(948)
  const [standardAnnually , setStandardAnnually] = useState(1428)

  const handleClick = () => {
    setOffer(!offer)
    if(offer === true){
      setStarterMonthly(79)
      setStandardMonthly(119)
      setStarterAnnually(948)
      setStandardAnnually(1428)
      document.getElementById("anually").classList.remove("is-hidden")
      document.getElementById("anually2").classList.remove("is-hidden")
      document.getElementById("anually3").classList.remove("is-hidden")
    }
    else{
      setStarterMonthly(99)
      setStandardMonthly(149)
      document.getElementById("anually").classList.add("is-hidden")
      document.getElementById("anually2").classList.add("is-hidden")
      document.getElementById("anually3").classList.add("is-hidden")
    }
  }
  

  const { t } = useTranslation();
  return (
    <motion.div 
    initial={{opacity:0, transition:{duration: 0.3}}}
    animate={{opacity:1, transition:{duration: 0.3}}}
    exit={{opacity:0, transition:{duration: 0.3}}}
    
    
    >
      <IternalHeroBlock
        heading={t('price_header')}
        para={t('p-s1-p')}
        page="pricing"
        handleClick={handleClick}
        offer={offer}
      />

<a href="https://wa.me/31649934714" class="float" target="_blank">
<img class="whatsapp" src={Whatsapp} alt="Whatsapp" />
</a>

      <section className="pricing">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-4  col-md-4 mb">
              <div className="price-til">
                <span className="tag">{t('p-s2-t1-ph')}</span>
                <p className="intro">
                {t('p-s2-t1-p')}
                </p>
                <h2>€{starterMonthly}/{t('p-month')}</h2>
                <p id="anually" className="postHeader">€{starterAnnually} {t('p-billedAnnually')}</p>
                <ul>
                  <li>
                    <p>{t('plan1-s2-b1')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan1-s2-b2')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan1-s2-b3')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan1-s2-b4')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li className="disabled">
                    <p>{t('plan1-s2-b5')}</p>
                    <span>
                      <img src={close} alt="" />
                    </span>
                  </li>
                  <li className="disabled">
                    <p>{t('plan1-s2-b6')}</p>
                    <span>
                      <img src={close} alt="" />
                    </span>
                  </li>
                  
                </ul>
                <Link to="https://my.tastyqr.nl/auth/signup" className="btn btn-solid-blue">
                  {t('p-start')}
                </Link>
              </div>
            </div>
            <div className="col-xl-4  col-md-4 mb">
              <div className="price-til">
                <span className="tag">{t('p-s2-t2-ph')}</span>
                <p className="intro">
                {t('p-s2-t2-p')}
                </p>
                <h2>€{standardMonthly}/{t('p-month')}</h2>
                <p id="anually2" className="postHeader">€{standardAnnually} {t('p-billedAnnually')}</p>
                <ul>
                  <li>
                    <p>{t('plan2-s2-b1')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan2-s2-b2')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan2-s2-b3')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan2-s2-b4')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan2-s2-b5')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li className="disabled">
                    <p>{t('plan2-s2-b6')}</p>
                    <span>
                      <img src={close} alt="" />
                    </span>
                  </li>

                </ul>
                <Link to="https://my.tastyqr.nl/auth/signup" className="btn btn-solid-blue">
                  {t('p-start')}
                </Link>
              </div>
            </div>
            <div className="col-xl-4  col-md-4 mb">
              <div className="price-til">
                <span className="tag">{t('p-s2-t3-ph')}</span>
                <p className="intro">
                {t('p-s2-t3-p')}
                </p>
                <h2>{t('professional')}</h2>
                <p id="anually3" className="postHeader white">Price is negotiable</p>
                <ul>
                  <li>
                    <p>{t('plan3-s2-b1')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan3-s2-b2')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan3-s2-b3')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan3-s2-b4')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan3-s2-b5')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                  <li>
                    <p>{t('plan3-s2-b6')}</p>
                    <span>
                      <img src={stick} alt="" />
                    </span>
                  </li>
                 
                </ul>
                <Link to="/contact" className="btn btn-hollow-blue price-btn-tablet-responsive-2">
                  {t('professional-start')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="compare-pricing">
        <div className="container-lg tablePrice">

        <h2>{t('compare')} <span><img src={dd} class="priceimage" alt="" /></span> </h2>
          <table className="w-100">
            <thead>
              <tr>
                <td></td>
                <td className="text-center">{t('p-s2-t1-ph')}</td>    
                <td className="text-center">{t('p-s2-t2-ph')}</td>    
                <td className="text-center">{t('p-s2-t3-ph')}</td>    
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('trial')}</td>
                <td className="text-center">30 {t('p-weeks')}</td>
                <td className="text-center"> 30 {t('p-weeks')}</td>
                <td className="text-center">30 {t('p-weeks')}</td>
              </tr>
              <tr>
                <td>{t('p-s2-b1')}</td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr>
              <tr>
                <td>{t('p-s2-b2')}</td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr>
              <tr>
                <td>{t('p-s2-b4')}</td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr>
              {/* <tr>
                <td>{t('p-s2-b7')}</td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr> */}
              <tr>
                <td>{t('p-s2-b9')}</td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr>
              <tr>
                <td>{t('p-s2-b10')}</td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr>
              <tr>
                <td>{t('p-s2-b11')}</td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center"><span> <img src={stick} alt="" /> </span></td>
              </tr>
              <tr>
                <td>{t('p-s2-b12')}</td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center">{t('location')}</td>
                <td className="text-center">{t('location-p')}</td>
              </tr>
              <tr>
                <td>Sticker Branding</td>
                <td className="text-center"><span> <img src={close} alt="" /> </span></td>
                <td className="text-center">{t('p-cl')} & {t('p-color')}</td>
                <td className="text-center">{t('p-fc')}</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-center"><Link to="https://my.tastyqr.nl/auth/signup" className="btn btn-solid-blue">
                  {t('p-start2')}
                </Link></td>
                <td className="text-center"><Link to="https://my.tastyqr.nl/auth/signup" className="btn btn-solid-blue">
                  {t('p-start2')}
                </Link></td>
                <td className="text-center"><Link to="/contact" className="btn btn-solid-blue">
                  {t('p-start3')}
                </Link></td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </section>



     <Faq/>
    </motion.div>
  );
};

export default Price;
