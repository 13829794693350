import React, { useEffect } from 'react'
import './Integrations.css'

import IternalHeroBlock from './IternalHeroBlock'
import { useTranslation } from 'react-i18next';


import index from '../assets/images/integrations/index.svg'
import lightspeed from '../assets/images/integrations/lightspeed.svg'
import vectron from '../assets/images/integrations/vectron.svg'
import until from '../assets/images/integrations/until.svg'
import Square from '../assets/images/integrations/Square.svg'
import Toast from '../assets/images/integrations/Toast.svg'
import Micas from '../assets/images/integrations/Micas.svg'
import MplusKASSA from '../assets/images/integrations/MplusKASSA.svg'
import Star from '../assets/images/integrations/Star.svg'
import Citizen from '../assets/images/integrations/Citizen.svg'
import Epson from '../assets/images/integrations/Epson.svg'

import Whatsapp from "../assets/images/whatsapp.svg";
import {motion} from 'framer-motion'



const Integrations = () => {


  const { t } = useTranslation();
  useEffect(()=>{
    document.title = "Integraties - TastyQR";
  }, [])
  return (
    <motion.div 
    initial={{opacity:0, transition:{duration: 0.3}}}
    animate={{opacity:1, transition:{duration: 0.3}}}
    exit={{opacity:0, transition:{duration: 0.3}}}
    
    
    >

<a href="https://wa.me/31649934714" class="float" target="_blank">
<img class="whatsapp" src={Whatsapp} alt="Whatsapp" />
</a>

        {/* hero block */}
       <IternalHeroBlock  heading={t('i-s1-h2')} 
        para={t('i-s10-p')}
        page='integrations'
        />

        <section className='pos'>
        <div className="container-lg">
          {/* <h2>{t('i-s2-h2')}</h2> */}
          <div className="row">
            <div className="col-xl-3">
              <div className="pos-tile lightspeed">
                <div className="logo">
                  <img src={lightspeed} alt="" />
                </div>
                <p>Lightspeed</p>
              </div>
              
            </div>
            <div className="col-xl-3">
              <div className="pos-tile">
                <div className="logo">
                    <img src={vectron} alt="" />
                </div>
                <p>Vectron</p>
              </div>
              
            </div>
            <div className="col-xl-3">
              <div className="pos-tile">
              <div className="logo">
                    <img src={until} alt="" />
                </div>
                <p>unTill</p>
              </div>
              
            </div>
            <div className="col-xl-3">
            <div className="pos-tile">
              <div className="logo">
                    <img src={index} alt="" />
                </div>
                <p>Index</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3">
              <div className="pos-tile">
                <div className="logo">
                  <img src={Square} alt="" />
                </div>
                <p>Square</p>
              </div>
              
            </div>
            <div className="col-xl-3">
              <div className="pos-tile">
                <div className="logo">
                    <img src={Toast} alt="" />
                </div>
                <p>Toast</p>
              </div>
              
            </div>
            <div className="col-xl-3">
              <div className="pos-tile">
              <div className="logo">
                    <img src={Micas} alt="" />
                </div>
                <p>Micas</p>
              </div>
              
            </div>
            <div className="col-xl-3">
            <div className="pos-tile">
              <div className="logo">
                    <img src={MplusKASSA} alt="" />
                </div>
                <p>MplusKASSA</p>
              </div>
            </div>
          </div>
        </div> 
            
            
        </section>
        {/* <section className='pos recipt'>
        <div className="container-lg">
          <h2>{t('i-s3-h2')}</h2>
          <div className="row">
            <div className="col-xl-3">
              <div className="pos-tile">
                <div className="logo">
                  <img src={Epson} alt="" />
                </div>
                <p>Epson</p>
              </div>
              
            </div>
            <div className="col-xl-3">
              <div className="pos-tile">
                <div className="logo">
                    <img src={Citizen} alt="" />
                </div>
                <p>Citizen</p>
              </div>
              
            </div>
            <div className="col-xl-3">
              <div className="pos-tile">
              <div className="logo">
                    <img src={Star} alt="" />
                </div>
                <p>Star</p>
              </div>
              
            </div>
          </div>
          
        </div> 
            
            
        </section> */}

    </motion.div>
   
  )
}

export default Integrations
