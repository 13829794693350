import React, { useEffect } from 'react'
import IternalHeroBlock from './IternalHeroBlock'
import Faq from './Faq'
import ReadySection from './ReadySection'
import demobanner from "../assets/images/demo/demo-banner.webp";
import arr1 from "../assets/images/demo/arr-1.svg";
import arr2 from "../assets/images/demo/arr-2.svg";
import arr3 from "../assets/images/demo/arr-3.svg";
import arr4 from "../assets/images/demo/arr-4.svg";
import './Demo.css'
import { useTranslation } from 'react-i18next'
import Whatsapp from "../assets/images/whatsapp.svg";




import {motion} from 'framer-motion'
import MobileAnime from './MobileAnime';

const Demo = () => {
  const { t, i18n } = useTranslation()
  useEffect(()=>{
    document.title = "Demo - TastyQR";
  }, [])
  return (
    <motion.div 
    initial={{opacity:0, transition:{duration: 0.3}}}
    animate={{opacity:1, transition:{duration: 0.3}}}
    exit={{opacity:0, transition:{duration: 0.3}}}
    
    
    >
      <IternalHeroBlock  heading={t('d-s1-h2')}
          para={t('d-s1-p')}
          page='demo'
      />

<a href="https://wa.me/31649934714" class="float" target="_blank">
<img class="whatsapp" src={Whatsapp} alt="Whatsapp" />
</a>

      <section className='demo'>
        <div className="container-lg">
         <div className="main-banner">
            {/* <img src={demobanner} alt="" /> */}
            <MobileAnime />
            <span className='arr1'><img src={arr1} alt="" /></span>
            <span  className='arr2'><img src={arr2} alt="" /></span>
            <span  className='arr3'><img src={arr3} alt="" /></span>
            <span  className='arr4'><img src={arr4} alt="" /></span>
         </div>
         <ul className="toottips">
          <li>
            <h6>{t('d-t1-h')}</h6>
            <p>
            {t('d-t1-p')}</p>
          </li>
          <li>
            <h6>{t('d-t2-h')}</h6>
            <p>
            {t('d-t2-p')}            </p>
          </li>
          <li>
            <h6>{t('d-t3-h')}</h6>
            <p>
            {t('d-t3-p')}            </p>
          </li>
          <li>
            <h6>{t('d-t4-h')}</h6>
            <p>
            {t('d-t3-p')}       
                 </p>
          </li>
         </ul>
            
        </div>
      </section>



      <Faq/>

      <ReadySection />
    </motion.div>
    
  )
}

export default Demo
