import React from 'react'
import './IternalHeroBlock.css'

import quaterCircle from '../assets/images/hero-quater-circle.svg';
import fullCircle from '../assets/images/hero-full-circle.svg';
import { useTranslation } from 'react-i18next';


const IternalHeroBlock = (props) => {
  const { t } = useTranslation();



  return (
    <section className='internalheroblock'>
            <div className='container-lg'>
                <div className="row">
                    <div className="col-xl-12">
                        <span className='quater-circle'><img src={quaterCircle} alt="" /></span>
                        <span className='full-circle'><img src={fullCircle} alt="" /></span>
                        <h1>{props.heading}</h1>
                        <p>
                            {props.para}
                        </p>
                        {
                          props.page === 'pricing'? (<>
                          
                          <div className="row">
                            <div className='offer d-flex w-auto mx-auto mt-5 align-items-center'>
                              <ul className='d-flex'>
                                <li onClick={props.handleClick} className={props.offer? 'selected': ''}>{t('p-monthly')}</li>
                                <li onClick={props.handleClick} className={ !props.offer? 'selected': ''} >{t('p-yearly')}</li>
                              </ul>
                              <span onClick={props.handleClick} className={ props.offer? 'd-none': ''}>{t('p-save')} 20%!</span>
                            </div>
                            
                          </div>
                          
                          
                          </>):(<></>)
                        }
                    </div>
                </div>
            </div>
    </section>
  )
}

export default IternalHeroBlock