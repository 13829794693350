import React, { useEffect, useState } from "react";
import IternalHeroBlock from "./IternalHeroBlock";
import Faq from "./Faq";
import Select from "react-select";

import "./Contact.css";
import { useTranslation } from "react-i18next";

import chat from "../assets/images/Contact/chat-icon.svg";
import mail from "../assets/images/Contact/mail-icon.svg";
import whatsapp from "../assets/images/whatsapp2.svg";
import clock from "../assets/images/Contact/clock.svg";
import phone from "../assets/images/Contact/phone.svg";
import envlop from "../assets/images/Contact/envelope.svg";
import pin from "../assets/images/Contact/pin-map.svg";
import Whatsapp from "../assets/images/whatsapp.svg";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Contact - TastyQR";
    const script = document.createElement("script");
    const body = document.getElementsByTagName("body")[0];
    script.src = "//js.hcaptcha.com/1/api.js";
    body.appendChild(script);
  }, []);

  const [contactFormStates, setContactFormStates] = useState({
    submitSuccess: false,
    submitError: false,
    invalidCaptcha: false,
  });

  const options = [
    { value: "1", label: "option 1" },
    { value: "1", label: "option 1" },
    { value: "1", label: "option 1" },
    { value: "1", label: "option 1" },
    { value: "1", label: "option 1" },
    { value: "1", label: "option 1" },
  ];

  const submitContact = async (e) => {
    function formSuccessCallback() {
      console.log("form submitted");
    }

    function invalidCaptchaCallback() {
      console.log("invalid captcha");
      setContactFormStates({
        ...contactFormStates,
        invalidCaptcha: true,
      });
      console.log(contactFormStates);
    }

    e.preventDefault();

    let hCatpchaCompleted = false;

    hCatpchaCompleted = await (async () => {
      return document
        .getElementById("hcaptcha1")
        .childNodes[0].getAttribute("data-hcaptcha-response");
    })().catch(invalidCaptchaCallback);

    if (!hCatpchaCompleted || hCatpchaCompleted.length <= 0)
      return invalidCaptchaCallback();

    console.log("captcha completed", hCatpchaCompleted);

    const data = new FormData(e.target);

    // const challenge = data.get('h-catpcha-response');
    const challenge = hCatpchaCompleted;

    fetch("http://api.tastyqr.nl/v1/contact", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      //cors
      body: JSON.stringify({
        name: data.get("name"),
        email: data.get("email"),
        company: data.get("company"),
        message: data.get("message"),
        challenge,
      }),
    }).then(async (res) => {
      const resJSON = await res.json();
      console.log(resJSON);
      if (res.ok && (resJSON.success || !resJSON.error)) {
        setContactFormStates({
          ...contactFormStates,
          submitSuccess: resJSON.message || true,
          submitError: false,
          invalidCaptcha: false,
        });
        formSuccessCallback();
      } else {
        setContactFormStates({
          ...contactFormStates,
          submitError: resJSON.message || res.statusText || true,
          invalidCaptcha: false,
        });
      }
    });
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#FEC168" : null,
        color: "#333333",
      };
    },
  };
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.3 } }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <IternalHeroBlock
        heading={t("c-s1-h1")}
        para={t("c-s1-p")}
        page="contact"
      />

      <a href="https://wa.me/31649934714" class="float" target="_blank">
        <img class="whatsapp" src={Whatsapp} alt="Whatsapp" />
      </a>

      <section className="contact-main">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <div className="contact-tile">
                <div className="icon">
                  <img src={whatsapp} alt="" />
                </div>
                <h6>{t("c-s2--t1-h")}</h6>
                <p>{t("c-s2--t1-p")}</p>
                <Link to="https://wa.me/31649934714" target="_blank">
                  {t("c-s2--t1-h")} &#8594;
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="contact-tile email">
                <div className="icon">
                  <img src={mail} alt="" />
                </div>
                <h6>{t("c-s2--t2-h")}</h6>
                <p>{t("c-s2--t2-p")}</p>
                <Link to="mailto:hello@tastyqr.nl" target="_blank">
                  {t("c-s2--t2-link")} &#8594;
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="contact-tile">
                <div className="icon">
                  <img src={chat} alt="" />
                </div>
                <h6>{t("c-s2--t3-h")}</h6>
                <p>{t("c-s2--t3-p")}</p>
                <Link
                  to="https://go.crisp.chat/chat/embed/?website_id=aa0731b0-f521-4309-85df-ddc21fa041bd"
                  target="_blank"
                >
                  {t("c-s2--t3-link")} &#8594;
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form">
        <div className="container-lg">
          <div className="row">
            {/* <div className="col-xl-5 col-md-5">
          <div className='text'>
              <h2>{t('c-s2-h1')}</h2>
              <ul>
                <li><span><img src={clock} alt="" /></span> {t('c-s2-b1')}</li>
                <li><span><img src={envlop} alt="" /></span> {t('c-s2-b2')}</li>
                <li><span><img src={phone} alt="" /></span> {t('c-s2-b3')}</li>
                <li><span><img src={pin} alt="" /></span> {t('c-s2-b4')}</li>
              </ul>
            </div>
          </div> */}
            <div>
              <h2>{t("c-s2-h1")}</h2>

              {contactFormStates.submitSuccess && (
                <div className="alert alert-success" role="alert">
                  Form submitted successfully!
                </div>
              )}

              {contactFormStates.invalidCaptcha && (
                <div className="alert alert-danger" role="alert">
                  {typeof contactFormStates.invalidCaptcha !== "boolean"
                    ? contactFormStates.invalidCaptcha
                    : "Invalid captcha"}
                </div>
              )}

              {contactFormStates.submitError && (
                <div className="alert alert-danger" role="alert">
                  {typeof contactFormStates.submitError !== "boolean"
                    ? contactFormStates.submitError
                    : "Error submitting form"}
                </div>
              )}

              <form action="#" onSubmit={submitContact}>
                {/* <div className="row">
              <div className="col-12">
                <label htmlFor="subject" className='w-100'>{t('c-s2-l1')}</label>
                <select name="topic" id="topic" className='w-100' required>
                  <option value="0" selected={true} disabled>{t('c-s2-p1')}</option>
                  <option value="0" >option 1</option>
                  <option value="0" >option 2</option>
                  <option value="0" >option 3</option>
                  <option value="0" >option 4</option>
                  <option value="0" >option 5</option>
                  <option value="0" >option 6</option>
                </select>
                <Select
                  value={options.value}
                  options={options}
                  defaultValue={options[0]} styles={colourStyles}
                />
              </div>
            </div> */}

                <div className="row">
                  <div className="col-12">
                    <label htmlFor="name" className="w-100 mt-4">
                      {t("c-s2-l1")}
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-100"
                      placeholder={t("c-s2-p2")}
                      required
                      disabled={contactFormStates.submitSuccess}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="company" className="w-100">
                      {t("c-s2-l2")}
                    </label>
                    <input
                      type="text"
                      name="company"
                      className="w-100"
                      placeholder={t("c-s2-p3")}
                      disabled={contactFormStates.submitSuccess}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="email" className="w-100">
                      {t("c-s2-l3")}
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="w-100"
                      placeholder={t("c-s2-p4")}
                      required
                      disabled={contactFormStates.submitSuccess}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="msg" className="w-100">
                      {t("c-s2-l4")}
                    </label>
                    <textarea
                      name="message"
                      id=""
                      className="w-100"
                      rows="5"
                      placeholder={t("c-s2-p5")}
                      required
                      minLength={20}
                      disabled={contactFormStates.submitSuccess}
                    ></textarea>
                  </div>
                </div>
                {!contactFormStates.submitSuccess && (
                  <div>
                    <div
                      id="hcaptcha1"
                      class="h-captcha"
                      data-sitekey="d627e569-19b6-4198-af7a-d9af030fc721"
                    ></div>
                    <button type="submit" className="btn btn-solid-blue">
                      {t("contact")}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>

      <Faq />
    </motion.div>
  );
};

export default Contact;
