import React, { useEffect } from "react";
import Logo from "../assets/images/logo.webp";
import Fb from "../assets/images/fb.svg";
import Twitter from "../assets/images/twitter.svg";
import Insta from "../assets/images/insta.svg";
import Location from "../assets/images/location_on.svg";
import Mail from "../assets/images/mail.svg";
import Phone from "../assets/images/phone_in_talk.svg";
import { Link } from "react-router-dom";
import Copyright from "./Copyright";
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import nlflag from "../assets/images/nl-flag.svg"

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-3 social col-md-6">
              <Link className="navbar-brand footer-logo" to="/">
                <img src={Logo} alt="Logo" width="150%" height="150%" />
              </Link>
              <ul>
                <li>
                  <p>
                    TastyQR is a modern QR ordering system for restaurants. Enhance your guest experience with TastyQR.
                  </p>
                </li>
                <li>
                  <ul className="d-flex justify-content-start social-icons ">
                    <li> 
                      <a href="https://www.facebook.com/profile.php?id=100093137296955" target="_blank">
                        <img src={Insta} />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/tastyqr_" target="_blank">
                        <img src={Twitter}/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/tastyqr_/" target="_blank">
                        <img src={Fb} target="_blank"/>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 company-1 col-md-6">
              <h5><strong>Links</strong></h5>
              <ul>
              <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/integrations"}>Integrations</Link>
                </li>
                {/* <li> 
                  <Link to={"/demo"}>Demo</Link>
                </li> */}
                <li>
                  <Link to={"/price"}>Price</Link>
                </li>
                <li>
                  <Link to={"/about"}>About</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 company-2 col-md-6">
              <h5><strong>Legal</strong></h5>
                <ul>
                  <li>
                    <Link to={"https://terms.tastyqr.nl/tastyqr_privacy_policy_en.pdf"} target="_blank">Privacy Policy</Link>
                  </li>
                  <li> 
                    <Link to={"https://terms.tastyqr.nl/tastyqr_terms_and_conditions_en.pdf"} target="_blank">Terms of Service</Link>
                  </li>
                </ul>
            </div>
            <div className="col-xl-3 contact col-md-6">
               <h5><strong>Contact us</strong></h5>
                <ul>
                  <li className="d-flex">
                    <div className="icon">
                        <img src={Location} alt="location" />
                    </div>
                    <div>
                      <p>
                        Martinus Houttuynhof 51
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="icon">
                        <img src={Mail} alt="mail" />
                    </div>
                    <div>
                      <a href="mailto:info@tastyqr.nl">hello@tastyqr.nl</a>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="icon">
                        <img src={Phone} alt="phone" />
                    </div>
                    <div>
                      <a href="https://wa.me/31649934714" target="_blank">+31 6 49934714 (whatsapp)</a>
                    </div>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </footer>
      <Copyright />
    </>
  );
};

export default Footer;
 