import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import OrderingSection from './OrderingSection'
import Testimonial from './Testimonial'
import Numbers from './Numbers'
import ReadySection from './ReadySection'
import { useTranslation } from 'react-i18next'
import OrderingBanner from "../assets/images/home-ordering-banner.webp";
import {motion} from 'framer-motion'



import HeroBanner from "../assets/images/home-hero-banner-1.webp";
import LearnBanner from "../assets/images/home-learn-banner-1.webp";
import Scan from "../assets/images/scan-tile-icon.svg";
import Whatsapp from "../assets/images/whatsapp.svg";
import Done from "../assets/images/done-tile-icon.svg";
import Order from "../assets/images/order-tile-icon.svg";
import Exparrow from "../assets/images/exp-arrow.svg";
import FeatureBanner from "../assets/images/featurBanner.webp";
import accMob from "../assets/images/qr-scan2.svg";
import paint from "../assets/images/paint.svg";
import payment from "../assets/images/payments.svg";
import upselling from "../assets/images/upselling.svg";
import MobileAnime from './MobileAnime';
import dashboard from '../assets/images/dashboard.png';
import standalone from '../assets/images/standalone.png';
import menu2 from '../assets/images/menu.png';

import money from "../assets/images/money.svg";
import qrscan from "../assets/images/qr-scan.svg";
import f3 from "../assets/images/f3.svg";
import f32 from "../assets/images/f32.svg";
import language from "../assets/images/language.svg";
import discount from "../assets/images/coupons.svg";
import reporting from "../assets/images/reporting.svg";
import reporting2 from "../assets/images/reporting2.svg";
import settings from "../assets/images/settings.svg";
import menu from "../assets/images/menu.svg";
import cooking from "../assets/images/cooking.svg";
import qr from "../assets/images/qr.svg";

import dtick from "../assets/images/double-tick.svg";
import stick from "../assets/images/single-tick.svg";


import increase from "../assets/images/increase.webp";
import decrease from "../assets/images/decrease.webp";
import gain from "../assets/images/gain.webp";


import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";


const Home = () => {

const { t } = useTranslation();
useEffect(()=>{
  document.title = "TastyQR - QR Bestellen voor de Horeca | Mobiel bestellen";
}, [])
  return (

    <motion.div 
    initial={{opacity:0, transition:{duration: 0.3}}}
    animate={{opacity:1, transition:{duration: 0.3}}}
    exit={{opacity:0, transition:{duration: 0.3}}}
    
    
    >
      {/* home hero section */}

<a href="https://wa.me/31649934714" class="float" target="_blank">
<img class="whatsapp" src={Whatsapp} alt="Whatsapp" />
</a>

      <section className="home-intro">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-6 col-md-6">
              <div className="text">
                <h1 class="heading">{t('h-s1-h1')}</h1>
                <h1>{t('bottom-s2-h1')}</h1>
                <p>
                {t('h-s1-p')}
                </p>
                <Link className="btn btn-yellow" to="https://my.tastyqr.nl/auth/signup">
                {t('h-s1-btn')}
                </Link>
              </div>
            </div>
            <div className="col-xl-6  col-md-6">
              <div className="home-hero-banner">
                <img width="89%" height="89%" src={HeroBanner} alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* home ordering section */}
      <OrderingSection />
      {/* home Learn section */}
      <section className="home-learn">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-6 col-md-6">
              <div className="text">
                <h2>{t('h-s3-h2')}</h2>
                <p>
                {t('h-s3-p-p1')} <strong>{t('h-s3-p-ps')}</strong> {t('h-s3-p-p3')}
                </p>
                <ul>
                  <li> <span><img src={dtick} alt="" /></span>  {t('h-s3-b1')}</li>
                  <li> <span><img src={dtick} alt="" /></span>  {t('h-s3-b2')}</li>
                  <li> <span><img src={dtick} alt="" /></span>  {t('h-s3-b3')}</li>
                  <li> <span><img src={dtick} alt="" /></span>  {t('h-s3-b4')}</li>
                  <li> <span><img src={dtick} alt="" /></span>   {t('h-s3-b5')}</li>
                  <li> <span><img src={dtick} alt="" /></span>  {t('h-s3-b6')}</li>
                  <li>
                  <span><img src={dtick} alt="" /></span>  
                  {t('h-s3-b7')}
                  </li>
                </ul>
                <Link className="btn btn-hollow-blue" to="https://my.tastyqr.nl/auth/signup">
                {t('h-s3-btn')}
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="learn-banner">
                <img width="87%" height="87%" src={LearnBanner} alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* home experience section */}

      {/* <section className="home-exp">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-7 col-md-7">
              <div className="text">
                <span className="preheader">{t('h-s4-ph')} </span>
                <h2>{t('h-s4-h2')}</h2>
                <ul>
                  <li className="d-flex">
                    <div className="tile-icon">
                      <img src={Scan} alt="Scan" />
                    </div>
                    <div className="tile-text">
                      <h5>{t('h-s4-t1-h')}</h5>
                      <p>
                        {t('h-s4-t1-p')}
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="tile-icon">
                      <img src={Order} alt="Order" />
                    </div>
                    <div className="tile-text">
                      <h5>{t('h-s4-t2-h')}</h5>
                      <p>
                      {t('h-s4-t2-p')}
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="tile-icon">
                      <img src={Done} alt="Done" />
                    </div>
                    <div className="tile-text">
                      <h5>{t('h-s4-t3-h')}</h5>
                      <p>
                      {t('h-s4-t3-p')}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-5 col-md-5">
              <div className="ordering-banner">
                <span>
                  <img src={Exparrow} alt="Banner" />
                </span>
                  <MobileAnime />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      
      {/* home 1 section */}
      {/* <section className="home-feature">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-5 col-md-5">
              <div className="text">
                <h2 class="functions">{t('h-s5-h2')}</h2>
              </div>
            </div>
            <div className="col-xl-7 col-md-7">
              <Tabs
                defaultActiveKey="hotel"
                id="uncontrolled-tab-example"
                className="features-tab"
              >
                <Tab eventKey="hotel" title={t('features-tab-1')}>
                <div className="feature-banner">
                  <img src={menu2} width="auto" height="460px" alt="Banner" />
                </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <span>
                          <img src={accMob} alt=""/>
                        </span>
                        {t('h-s5-t1-h')}
                      </Accordion.Header>
                      <Accordion.Body>
                      {t('h-s5-t1-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                      <span>
                          <img src={payment}  alt=""/>
                        </span>
                        {t('h-s5-t2-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h-s5-t2-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      <span>
                          <img src={paint} alt="" />
                        </span>
                        {t('h-s5-t3-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h-s5-t3-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                      <span>
                          <img src={upselling} alt="" />
                        </span>
                        {t('h-s5-t4-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h-s5-t4-p')}                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                <Tab eventKey="restaurant" title={t('features-tab-2')}>
                <div className="feature-banner">
                  <img src={dashboard} alt="Banner" width="400px" height="auto" />
                </div>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <span>
                          <img src={reporting2} alt=""/>
                        </span>
                        {t('h2-s5-t1-h')}
                      </Accordion.Header>
                      <Accordion.Body>
                      {t('h2-s5-t1-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                      <span>
                          <img src={settings}  alt=""/>
                        </span>
                        {t('h2-s5-t2-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h2-s5-t2-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      <span>
                          <img src={f32} alt="" />
                        </span>
                        {t('h2-s5-t3-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h2-s5-t3-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                      <span>
                          <img src={accMob} alt="" />
                        </span>
                        {t('h2-s5-t4-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h2-s5-t4-p')}                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                <Tab eventKey="bar" title={t('features-tab-3')}>
                <div className="feature-banner">
                  <img src={standalone} width="300px" height="auto" alt="Banner" />
                </div>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <span>
                          <img src={cooking} alt=""/>
                        </span>
                        {t('h3-s5-t1-h')}
                      </Accordion.Header>
                      <Accordion.Body>
                      {t('h3-s5-t1-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                      <span>
                          <img src={qr}  alt=""/>
                        </span>
                        {t('h3-s5-t2-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h3-s5-t2-p')}                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      <span>
                          <img src={menu} alt="" />
                        </span>
                        {t('h3-s5-t3-h')}</Accordion.Header>
                      <Accordion.Body>
                      {t('h3-s5-t3-p')}                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
              </Tabs>
            </div>
          </div>
          
        </div>

        <div className="row op-buttons featurebtn">
            <div className="col-xl-12 text-center">
              <a class="btn btn-solid-blue" href="https://www.tastyqr.nl/maintenance/">{t('h-s7-btn2')}</a>
              <a href="/contact">{t('h-s7-link')}</a>
            </div>
          </div>

      </section> */}

      {/* home feature-2 section */}
      <section className="home-feature-2">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-12 text">
              <h2 className="text-center">{t('h-s6-h2')}</h2>
              <p className="text-center">{t('h-s6-p')}
              </p>
            </div>
          </div>
          <div className="row feature-tiles">
            <div className="col-xl-4  col-md-4">
            <div className="feature-tile">
                <div className="icon">
                  <img src={money} alt="" />
                </div>
                <h4>{t('h-s6-t1-h')}</h4>
                <p>
                {t('h-s6-t1-p')}
                </p>
            </div>
                
            </div>
            <div className="col-xl-4  col-md-4">
              <div className="feature-tile">
                  <div className="icon">
                    <img src={qrscan} alt="" />
                  </div>
                  <h4>{t('h-s6-t2-h')}</h4>
                  <p>
                  {t('h-s6-t2-p')}
                  </p>
              </div>
                
            </div>
            <div className="col-xl-4  col-md-4 ">
            <div className="feature-tile">
                <div className="icon">
                  <img src={f3} alt="" />
                </div>
                <h4>{t('h-s6-t3-h')}</h4>
                <p>
                {t('h-s6-t3-p')}
                </p>
            </div>
                
            </div>
            <div className="col-xl-4  col-md-4">
            <div className="feature-tile">
                <div className="icon easy-icon">
                  <img src={language} alt="" />
                </div>
                <h4>{t('h-s6-t4-h')}</h4>
                <p>
                {t('h-s6-t4-p')}
                </p>
            </div>
                
            </div>
            <div className="col-xl-4  col-md-4">
            <div className="feature-tile">
                <div className="icon">
                  <img src={discount} alt="" />
                </div>
                <h4>{t('h-s6-t5-h')}</h4>
                <p>
                {t('h-s6-t5-p')}
                </p>
            </div>
                
            </div>
            <div className="col-xl-4  col-md-4">
            <div className="feature-tile">
                <div className="icon">
                  <img src={reporting} alt="" />
                </div>
                <h4>{t('h-s6-t6-h')}</h4>
                <p>
                {t('h-s6-t6-p')}
                </p>
            </div>
                
            </div>
            
          </div>
        </div>
      </section>
      {/* home operators section */}
      <section className="home-operators">
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-12 text">
              <h2 className="text-center">{t('h-s7-h2')} </h2>
              <p className="text-center">{t('h-s7-p')}</p>
            </div>
          </div>
          <div className="row op-tiles">
            
            <div className="col-xl-4  col-md-4">
            
                <div className="img">
                  <img src={increase} alt="" width="100%" height="100%" />
                </div>
                <div className="op-tile">
                <h4>{t('h-s7-t1-h')}</h4>
                <ul>
                  <li>  <span><img src={stick} alt="" /></span>  {t('h-s7-t1-b1')}</li>
                  <li> <span><img src={stick} alt="" /></span> {t('h-s7-t1-b2')}</li>
                  <li> <span><img src={stick} alt="" /></span> {t('h-s7-t1-b3')}</li>
                </ul>
            </div>
                
            </div>
            <div className="col-xl-4  col-md-4" >
             
                  <div className="img">
                    <img src={decrease} alt="" />
                  </div>
                   <div className="op-tile">
                  <h4>{t('h-s7-t2-h')}</h4>
                  <ul>
                    <li> <span><img src={stick} alt="" /></span>{t('h-s7-t2-b1')}</li>
                    <li> <span><img src={stick} alt="" /></span> {t('h-s7-t2-b2')}</li>
                    <li> <span><img src={stick} alt="" /></span> {t('h-s7-t2-b3')}</li>
                </ul>
              </div>
                
            </div>
            <div className="col-xl-4  col-md-4">
              
                  <div className="img">
                    <img src={gain} alt="" />
                  </div>
                  <div className="op-tile">
                  <h4>{t('h-s7-t3-h')}</h4>
                  <ul>
                    <li> <span><img src={stick} alt="" /></span>{t('h-s7-t3-b1')}</li>
                    <li> <span><img src={stick} alt="" /></span> {t('h-s7-t3-b2')}</li>
                    <li> <span><img src={stick} alt="" /></span> {t('h-s7-t3-b3')}</li>
                </ul>
              </div>
                
            </div>
            
          </div>
          {/* <div className="row op-buttons ">
            <div className="col-xl-12 text-center">
              <a class="btn btn-solid-blue" href="https://www.tastyqr.nl/maintenance/">{t('h-s7-btn')}</a>
              <a href="/contact">{t('h-s7-link')}</a>
            </div>
          </div> */}
            
        </div>
      </section>

      {/* home customer section */}

      {/* Escondidos */}
      {/* <Testimonial /> */}

      {/* home numbers section */}

      {/* Escondidos */}
      {/* <Numbers /> */}
      

      {/* home ready section */}
      <ReadySection />
    </motion.div>
  );
};

export default Home;
