import React, { useEffect } from 'react'
import IternalHeroBlock from './IternalHeroBlock'
import Testimonial from './Testimonial'
import Numbers from './Numbers'
import OrderingSection from './OrderingSection'
import { useTranslation } from 'react-i18next';

import './About.css'
import Whatsapp from "../assets/images/whatsapp.svg";
import r1 from '../assets/images/About/ralph.svg'
import r2 from '../assets/images/About/ralph-1.svg'
import r3 from '../assets/images/About/ralph-2.svg'
import { Link } from 'react-router-dom'
import AboutImg from "../assets/images/tastyqr-3d-office-1.webp";
import {motion} from 'framer-motion'

const About = () => {
  const { t } = useTranslation();

  useEffect(()=>{
    document.title = "Over Ons - TastyQR";
  }, [])

  
  return (
    <motion.div 
    initial={{opacity:0, transition:{duration: 0.3}}}
    animate={{opacity:1, transition:{duration: 0.3}}}
    exit={{opacity:0, transition:{duration: 0.3}}}
    
    
    >
      <IternalHeroBlock  heading={t('about_title')} 
        para={t('about_description')}
        page='about'
      /> 

<a href="https://wa.me/31649934714" class="float" target="_blank">
<img class="whatsapp" src={Whatsapp} alt="Whatsapp" />
</a>

      <section className="home-ordering">
    <div className="container-lg">
      <div className="row">
        <div className="col-xl-5 col-md-5">
          <div className="ordering-banner">
            <img src={AboutImg} alt="Banner" class="aboutimg" />
          </div>
        </div>
        <div className="col-xl-7 col-md-7">
          <div className="text">
            <span className="preheader">{t('about-label')}</span>
            <h2>{t('about-head')}</h2>
            <p>
            {t('about-text')}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

<div class="flex">
  <section className="home-ordering margin-right margin-left">
    <div className="container-lg">
      <div className="row">
        <div>
          <div className="text-justify">
            <span className="preheader">{t('about-label2')}</span>
            <h2>{t('about-head2')}</h2>
            <p class="text-justify">
            {t('about-text2')}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="home-ordering margin-right2 margin-left2">
    <div className="container-lg">
      <div className="row">
        <div>
          <div className="text-justify">
            <span className="preheader">{t('about-label3')}</span>
            <h2>{t('about-head3')}</h2>
            <p class="text-justify">
            {t('about-text3')}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
      {/* <Numbers /> */}
      {/* <section className='about-team'>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-7">
              <h2>{t('a-s3-h1')}</h2>
              <p>{t('a-s3-p')}</p>
            </div>
            <div className="col-xl-5 d-flex align-items-end justify-content-end">
                <Link to="#" class="btn btn-hollow-blue">See All Teams</Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <div className="member-tile">
                <div className='member'>
                    <img src={r1} alt="" />
                </div>
                <div className="detail">
                    <h3>Ralph Edwards</h3>
                    <p>{t('a-s3-t1-post')}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="member-tile">
                <div className='member'>
                    <img src={r2} alt="" />
                </div>
                <div className="detail">
                    <h3>Ralph Edwards</h3>
                    <p>{t('a-s3-t2-post')}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="member-tile">
                <div className='member'>
                    <img src={r3} alt="" />
                </div>
                <div className="detail">
                    <h3>Ralph Edwards</h3>
                    <p>{t('a-s3-t3-post')}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 d-flex justify-content-center mt-4 d-sm-none">
                <Link to="#" class="btn btn-hollow-blue">{t('a-s3-btn')}</Link>
            </div>
           
          </div>

        </div>
      </section> */}
      {/* <Testimonial /> */}
    </motion.div>
    
  )
}

export default About
