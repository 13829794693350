import React, { useState, useEffect } from "react";
import Logo from '../assets/images/headerLogo.webp'
import Cookies from "js-cookie";

import {Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import nlflag from "../assets/images/nl-flag.svg"
 
export function LockClosedIcon(props) {
  return (
    <svg aria-hidden="true" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}


const Header = () => {
  const currentLocale = Cookies.get("i18next") || "nl";

  const navHider = () => {
   
    const el = document.querySelector('#navbarNav');
    el.classList.remove("show");
    
  }

  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(currentLocale);

  const options = [
    { value: 'nl', label: `NL`,},
    { value: 'en', label: `EN`,}
  ];

  const Option = ({ label, icon }) => (
    <div>
                <div className="icon">
                  <img src={icon} alt="" style={{marginRight: '3em', display: 'flex'}} />
                </div>
      {label}
    </div>
  );  
  
  const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#FEC168" : null,
      color: "#333333"
    };
  }
  };
  const handleChangeLocale = (e) => {
    console.log(t);
    const lang = e;
    setLanguage(lang);
    Cookies.set("i18next", lang);
    i18n.changeLanguage(lang);
  };

  useEffect(()=>{
    setTimeout(()=> {
      setLanguage(currentLocale);
      handleChangeLocale(currentLocale);
      i18n.changeLanguage(currentLocale);
    });
  }, [])
  

  return (


    

    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-lg">
        <Link className="navbar-brand" to="/">
          <img src={Logo} width="180px" height="auto" alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item" onClick={navHider}>
              <Link className="nav-link" aria-current="page" to="/" >
              {t('Home')}
              </Link>
            </li>
            {/* <li className="nav-item" onClick={navHider}>
              <Link className="nav-link" to="/integrations">
              {t('Integrations')}
              </Link>
            </li> */}
            { <li className="nav-item" onClick={navHider}>
              <Link className="nav-link" to="/demo">
              {t('Demo')}
              </Link>
            </li> }
            <li className="nav-item" onClick={navHider}>
              <Link className="nav-link" to="/price">
              {t('Price')}
              </Link>
            </li>
            <li className="nav-item" onClick={navHider}>
              <Link className="nav-link" to="/about">
              {t('About')}
              </Link>
            </li>

            <li className="nav-item" onClick={navHider}>
              <Link className="nav-link" to="/contact">
              {t('Contact')}
              </Link>
            </li>
          </ul>
           <div className="nav-right d-flex">
                {/* <div className="lang d-flex align-items-center" >
                    <select className="form-select selectpicker" aria-label="Default select" onChange={handleChangeLocale} value={language}>
                    <option value="en">EN</option>
                    <option value="nl">NL</option>
                </select> 
                </div>*/}
                <Select
                  value={options.value}
                  options={options}
                  // components={{
                  //   Option
                  // }}
                  defaultValue={options.map((el, index)=> {
                    if(el.value == currentLocale) return el;
                  })}
                  onChange={(e)=>handleChangeLocale(e.value)} styles={colourStyles}
                />
                <a href="https://my.tastyqr.nl/auth/signup"><button type="button" className="btn btn-default btn-yellow button" onClick={navHider}>{t('Sign-Up')}</button></a>
           </div>
        </div>
      </div>
    </nav>

  );
};

export default Header;
