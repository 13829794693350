import React from 'react'
import Home from './Home';
import Integrations from './Integrations';
import Demo from './Demo';
import Price from './Price';
import About from './About';
import Contact from './Contact';

import {Route, Routes, useLocation } from 'react-router-dom';
import {AnimatePresence} from 'framer-motion'

const AnimatedRoutes = () => {

    const location = useLocation();

  return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route  path='/'  Component={Home} />
            <Route  path='/integrations' Component={Integrations} />
            <Route  path='/demo' Component={Demo} />
            <Route  path='/price' Component={Price} />
            <Route  path='/about' Component={About} />
            <Route  path='/contact' Component={Contact} />
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes