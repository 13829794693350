import React from 'react'

const Copyright = () => {
  return (
    <>
    
    <section className='copyright'> 
         <div className="container-lg">
            <div className="row">
            <div className="col-xl-12">
                <p className="text-center">
                    © 2023 TastyQR. All rights reserved
                </p>
            </div>
            </div>
        </div>
    </section>
       
    </>
  )
}

export default Copyright