import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "price_header": "Clear pricing, no hidden costs.",
      "integration_header": "Seamless integration into your business",
      // header - menus
      "Home": "Home",
      "Integrations": "Integrations",
      "Demo": "Demo",
      "Price": "Price",
      "About": "About",
      "Contact": "Contact",
      "Sign-Up": "Try for Free",
      // home page
      "h-s1-h1": `TastyQR,`, 
      "bottom-s2-h1": `the future is here`, 
      "h-s1-p": "Boost efficiency and enhance customer experience with QR menu ordering. Take your restaurant to the next level: Digitalize menus and automate orders.",
      "h-s1-btn": "Try for free",

      "h-s2-ph": "SMART QR ORDERING SYSTEM",
      "h-s2-h1": "TastyQR Ordering & Payment Solutions",
      "h-s2-p": "Enhance your guest experience with TastyQR ordering solutions. Let your customers view your branded digital menu, place their orders and pay the bills to avoid errors and save time. Your staff will have more time for hospitality. TastyQR increases the order amounts with smart upsells and cross-sells.",
      "h-s2-btn": "Sign up now",

      "h-s3-h2": "Learn why Tasty QR is great for your business",
      "h-s3-p-p1": "The",
      "h-s3-p-ps": "TastyQR",
      "h-s3-p-p3": "dashboard offers all features required to manage your ordering environment, from small businesses to enterprises.",
      "h-s3-b1": "Consistent up- and cross-selling",
      "h-s3-b2": "Save on staff costs",
      "h-s3-b3": "Increase your turnover",
      "h-s3-b4": "Integration with POS systems",
      "h-s3-b5": "Implement your own brand identity",
      "h-s3-b6": "Extensive insights and reports",
      "h-s3-b7": "Customize your menu easily",
      "h-s3-btn": "Try for free",

      "h-s4-ph": "No app needed",
      "h-s4-h2": "Experience how easy it is",
      "h-s4-t1-h": "Scan",
      "h-s4-t1-p": "Scan the QR code with their phone and get redirected to the menu with the table number already assigned.",
      "h-s4-t2-h": "Order",
      "h-s4-t2-p": "Choose what you want to eat, add a tip and place the order easily paying online or paying manually after.",
      "h-s4-t3-h": "Done",
      "h-s4-t3-p": "The details of every order are sent automatically to your staff, so they can start preparing it.",

      "h-s5-h2": "Features",
      "h-s5-p": "",
      "features-tab-1": "Menu",
      "features-tab-2": "Dashboard",
      "features-tab-3": "Tablet",
      "h-s5-t1-h": "Online Ordering",
      "h-s5-t1-p": "Boost your restaurant sales as customers enjoy a seamless ordering process, reducing wait times and increasing efficiency.",
      "h-s5-t2-h": "Easy Payments",
      "h-s5-t2-p": "Streamline payments with our online ordering system. Take advantage of Stripe integration and accept payments seamlessly with terminal compatibility.",
      "h-s5-t3-h": "Highly Customizable",
      "h-s5-t3-p": "Take control of your menu with our highly customizable system. Easily update and personalize your offerings to suit your unique brand and culinary vision.",
      "h-s5-t4-h": "Cross & Up Selling",
      "h-s5-t4-p": "Boost your revenue with our system's cross-selling and upselling features, strategically suggesting additional items during the checkout process.",

      "h2-s5-t1-h": "Advanced reporting",
      "h2-s5-t1-p": "Gain valuable insights with our system's advanced reporting, providing comprehensive data and analytics to optimize your business strategies.",
      "h2-s5-t2-h": "Manage your restaurant easily",
      "h2-s5-t2-p": "Effortlessly handle sub-users, QR codes, discount codes, POS connections, payment methods, currency, menu customization & more.",
      "h2-s5-t3-h": "POS Connections",
      "h2-s5-t3-p": "Simplify your operations with our seamless POS connections. Connect our QR system to your POS for automatic order integration and real-time product and stock synchronization.",
      "h2-s5-t4-h": "Manage QR codes",
      "h2-s5-t4-p": "Effortlessly assign QR codes to tables with our convenient feature, allowing users to generate and manage QR codes hassle-free.",

      "h3-s5-t1-h": "Manage Orders",
      "h3-s5-t1-p": "After guests place their order via the QR Menu, the order automatically appears in the tablet with all the necessary information to prepare the order.",
      "h3-s5-t2-h": "Manage QR Codes",
      "h3-s5-t2-p": "The tablet lets you scan new QR codes to assign them to a table or to temporarily deactivate QR codes.",
      "h3-s5-t3-h": "Manage Inventory",
      "h3-s5-t3-p": "Easily manage your inventory with the tablet. Modify prices of certain products, disable certain products (due to out of stock) & more.",

      "h-s6-h2": "Discover the possibilities",
      "h-s6-p": "Elevate your restaurant experience with innovative tools and endless possibilities.",
      "h-s6-t1-h": "Increased tips",
      "h-s6-t1-p": "Encourage customer generosity with an integrated feature that allows customers to add tips (optional) to their orders, boosting your staff earnings.",
      "h-s6-t2-h": "Quick and contactless ordering",
      "h-s6-t2-p": "Enable customers to scan the QR code and place orders directly from their smartphones, reducing wait times.",
      "h-s6-t3-h": "Seamless integration",
      "h-s6-t3-p": "Automate order processing by integrating with the restaurant's point-of-sale (POS) and kitchen systems for efficient workflow management.",
      "h-s6-t4-h": "Multi-language support",
      "h-s6-t4-p": "Cater to a diverse customer base with multi-language support, ensuring that customers can view and order from the menu in their preferred language.",
      "h-s6-t5-h": "Promotions and special offers",
      "h-s6-t5-p": "Showcase limited-time offers, discounts, or loyalty rewards to incentivize repeat business.",
      "h-s6-t6-h": "Analytics and reporting",
      "h-s6-t6-p": "Gain insights into customer ordering patterns, popular dishes, and sales performance to make data-driven decisions.",
      
      "h-s7-h2": "Why operators choose TastyQR",
      "h-s7-p": "Reasons why TastyQR is valuable for your business and why it is worth the small investment. Modernize your restaurant with TastyQR and enjoy of the many advantages it offers.",
      "h-s7-t1-h": "Increase your revenue",
      "h-s7-t1-b1": "Increased Table Turnover",
      "h-s7-t1-b2": "Up & cross selling",
      "h-s7-t1-b3": "Increased Tips",

      "h-s7-t2-h": "Decrease operational costs",
      "h-s7-t2-b1": "Decreased waiting times",
      "h-s7-t2-b2": "Efficient order processing",
      "h-s7-t2-b3": "Less staff required",

      "h-s7-t3-h": "Gain more control",
      "h-s7-t3-b1": "Extensive insights and reports",
      "h-s7-t3-b2": "Easy to manage & edit the menu",
      "h-s7-t3-b3": "Get to know your customers",
      "h-s7-btn": "Get Started",
      "h-s7-btn2": "Try for free",
      "h-s7-link": "Any questions?",

      "h-s8-h2": "what our customers say",
      "h-s8-p": "what our customers say",

      "h-s8-t1-P": "“We denounce with righteous indignation and dislike men who areso beguiled and by the charms of pleasure of the moment.”",
      "h-s8-t1-post": "Reporter",

      "h-s8-t2-P": "“We denounce with righteous indignation and dislike men who areso beguiled and by the charms of pleasure of the moment.”",
      "h-s8-t2-post": "Reporter",

      "h-s8-t3-P": "“We denounce with righteous indignation and dislike men who areso beguiled and by the charms of pleasure of the moment.”",
      "h-s8-t3-post": "Reporter",
      
      "h-s9-h2": "Some numbers that matter",
      "h-s9-p": "There is a reason why we are the best in the market.",

      "h-s9-b1": "Countries",
      "h-s9-b2": "Customer",
      "h-s9-b3": "Orders",
      "h-s9-b4": "Award",

      "h-s10-h2": "Ready to explore the future?",
      "h-s10-p": "Get started for free, no credit card required.",
      "h-s10-btn": "Try for free",

      // Integration Page
      "i-s1-h2": "Seamless integration with your POS",
      "i-s10-p": "Easy integrations with your POS to automate stock and automatically redirect orders from TastyQR to your POS system.",
      
      "i-s2-h2": "POS integrations",
      "i-s3-h2": "Receipt printers",
      
      // DEmo Page

      "d-s1-h2": "Menu demo",
      "d-s1-p": "Experience how your menu could look like. You are able to customize your menu to match the style of your restaurant.",

      "d-t1-h": "1. Customizable Logo",
      "d-t2-h": "2. Your own products & categories",
      "d-t3-h": "3. Allow online orders",
      "d-t4-h": "4. Tip at checkout",

      "d-t1-p": " Upload your logo and customize it with your brand's colors, enhancing your online menu's visual appeal.",
      "d-t2-p": " Set up and manage your products and categories with ease, including customizable options and translations for a tailored experience.",
      "d-t3-p": " Enable seamless online orders through your menu, reducing errors and improving operational efficiency.",
      "d-t4-p": " Incorporate a user-friendly tipping feature at checkout, allowing customers to express their gratitude effortlessly.",

      // faqs
      "f-h2": "Your frequently asked questions",
      "f-p": "If you don’t find answers to your questions here, don’t hesitate to ask. Our customer service team is always ready to help.",


      "f1-h": "How does a POS integration work?",
      "f2-h": "How does TastyQR work without a POS integration?",
      "f3-h": "What are the transaction costs when guests pay online?",
      "f4-h": "Isn't QR ordering considered impersonal?",
      "f5-h": "Do guests always have to pay immediately?",

      "f1-p": "When an integration with your POS is possible, TastyQR actually works as a handheld for your guests. Guests can order themselves, after which the order is automatically added to your POS. With a POS Integration, you can also import & sync products, categories, prices and more to the QR menu.",
      "f2-p": "TastyQR also works well without a POS integration. In this case, the restaurant can choose to send the orders directly to their existing printers. If this is not desired, we can also let the orders come in on a tablet next to the cash register.",
      "f3-p": "iDEAL: €0,29.  MasterCard/VISA (EU): €0,15 + 1,5%.  MasterCard/VISA (Non-EU/Business): €0,15 + 3,7%.  AMEX (EU/Non-EU/Business): €0,25 + 2,6%.  Maestro: €0,40.  Bancontact: €0,20",
      "f4-p": "It's actually not. We see that QR ordering gives guests an extra form of freedom if QR ordering is used in a supportive way. Your guests have several options to order a drink; via the control, or via the online menu for example.",
      "f5-p": "This is not necessary with TastyQR Standard and Professional subscriptions. With these subscriptions you can choose to have the guest pay by with your current terminal afterwards. This means that the order is placed with the QR code and afterwards your waiters give the terminal to your guest to pay.",


      // price page
      "p-s1-p": "Try TastyQR the first 30 days for free. After that, we charge a fixed fee.",

      
      "p-monthly": "Monthly",
      "p-yearly": "Yearly",
      "p-save": "Save",

      "p-month": "month",
      "p-billedAnnually": "Billed Annually",
      "professional": "Custom",
      "professional-start": "Contact us",
      
      
    

      "p-s2-t1-ph": "Starter",
      "p-s2-t2-ph": "Standard",
      "p-s2-t3-ph": "Professional",
      
      "p-s2-t1-p": "Recommended for small businesses with less than 20 tables.",
      "p-s2-t2-p": "Suitable for every type of business, the most chosen subscription.",
      "p-s2-t3-p": "Suitable for any type of business, make the experience even more personal.",

      "p-s2-b1": "Tablet included",
      "p-s2-b2": "Unlimited orders",
      "p-s2-b3": "Online mobile payments",
      "p-s2-b4": "Unlimited tables",
      "p-s2-b5": "Guests can order on tab",
      "p-s2-b7": "POS Integration",
      "p-s2-b8": "Takeaway & delivery platform",
      "p-s2-b9": "Upselling",
      "p-s2-b10": "Cross-Selling",
      "p-s2-b11": "POS integration",
      "p-s2-b12": "Extra locations",
      "p-s2-b13": "Branding :",
      "p-cl": "Custom Logo",
      "p-color": "Colors",
      "p-fc": "Fully Customized",
      "p-weeks": "Days",
      "p-start": "Start now 30 days for free",
      "p-start2": "Start now",
      "p-start3": "Contact us",
      "compare": "Compare the plans",

      "trial": "trial",
      "plan1-s2-b1": "Tablet included",
      "plan1-s2-b2": "Unlimited orders",
      "plan1-s2-b3": "Unlimited tables",
      "plan1-s2-b4": "Generic QR design",
      "plan1-s2-b5": "Upselling & Cross Selling",
      "plan1-s2-b6": "POS Integration",

      "plan2-s2-b1": "Tablet included",
      "plan2-s2-b2": "Unlimited orders",
      "plan2-s2-b3": "Unlimited tables",
      "plan2-s2-b4": "Custom QR Branding",
      "plan2-s2-b5": "Upselling & Cross Selling",
      "plan2-s2-b6": "POS Integration",

      "plan3-s2-b1": "Tablet Included",
      "plan3-s2-b2": "Unlimited orders",
      "plan3-s2-b3": "Unlimited tables",
      "plan3-s2-b4": "Fully customized branding",
      "plan3-s2-b5": "Upselling & Cross Selling",
      "plan3-s2-b6": "POS Integration",

      "location": "$79 p/m per location",
      "location-p": "Custom",

      // About Page
      'about_title': "About us",
      'about_description': "Who are we? Get to know us and learn about our vission.",
      "a-title": "TastyQR - About Us",
      "a-s3-h1": "Our ExpertsTeam Members",
      "a-s3-p": "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",

      "a-s3-t1-post": "UI/UX Designer",
      "a-s3-t2-post": "UI/UX Designer",
      "a-s3-t3-post": "UI/UX Designer",
      "a-s3-btn": "See All Teams",
      'about-label': "ABOUT US",
      'about-head': "Welcome to TastyQR",
      'about-text': "TastyQR, your gateway to a new era of dining convenience and efficiency. We are passionate about transforming the restaurant experience for both diners and restaurateurs through our innovative QR ordering system. With TastyQR.nl, we're on a mission to simplify restaurant operations and enhance the dining experience, one QR code at a time.",

      'about-label2': "WHY WE STAND OUT",
      'about-head2': "The TastyQR Difference",
      'about-text2': "At TastyQR.nl, we stand out as pioneers in the QR ordering system industry. We've harnessed the power of QR codes to provide a streamlined, user-friendly, and contactless dining solution. With just a scan of a QR code, diners can access restaurant menus, place orders, and make payments right from their own smartphones. This innovative approach not only enhances the dining experience but also improves table turnover and order accuracy for restaurant owners.",

      'about-label3': "HOW WILL THE FUTURE LOOK LIKE",
      'about-head3': "Our Vision",
      'about-text3': "Our vision is to be at the forefront of the dining revolution, making every restaurant visit a memorable experience. We believe that technology should enhance, not replace, the warmth and hospitality of dining out. TastyQR.nl is committed to bringing restaurant-goers and owners closer together in a digital age while preserving the joy of shared meals and the authenticity of in-person service.",
      
      // Contact Page
      "c-s1-h1": "Get in touch with us",
      "c-s1-p": "You can reach us by Whatsapp, Live Chat or Email and we will help you as soon as possible.",

      "c-s2--t1-h": "Whatsapp",
      "c-s2--t2-h": "Email Contact",
      "c-s2--t3-h": "Live chat",

      "c-s2--t1-p": "Expected response time: 3 hours",
      "c-s2--t2-p": "Expected response time: 24 hours",
      "c-s2--t3-p": "Expected response time: 6 hours",

      "c-s2--t1-link": "Chat now",
      "c-s2--t2-link": "Send email",
      "c-s2--t3-link": "Chat now",

      "c-s2-h1": "Contact us",
      "c-s2-b1": "Monday - Friday 9am to 5pm PST",
      "c-s2-b2": "support@Docmate.com",
      "c-s2-b3": "+13-225-456-7890",
      "c-s2-b4": " 1901 Thornridge Cir. Shiloh, Hawaii 81063T",

      "c-s2-l1": "Name *",
      "c-s2-l2": "Company name",
      "c-s2-l3": "Email *",
      "c-s2-l4": "Message *",
      "c-s2-l5": "Message",

      "c-s2-p1": "Please Select Topic",
      "c-s2-p2": "Enter your name here",
      "c-s2-p3": "Enter your company name here",
      "c-s2-p4": "Enter Your email here",
      "c-s2-p5": "Enter your message here",
      "contact": "Send",

    }
  },
  
  es: {
    "translation": {
    "price_header": "Precios claros, sin costos ocultos.",
    "integration_header": "Integración perfecta en tu negocio",
    "Home": "Inicio",
    "Integrations": "Integraciones",
    "Demo": "Demo",
    "Price": "Precios",
    "About": "Sobre nosotros",
    "Contact": "Contacto",
    "Sign-Up": "Prueba Gratuita",
    "h-s1-h1": "TastyQR,",
    "bottom-s2-h1": "el futuro es ahora",
    "h-s1-p": "Ofrece un servicio eficiente mejorando la experiencia del cliente permitiendo pedir mediante QR's. Lleva tu restaurante al siguiente nivel: digitaliza tus menús y automatiza los pedidos.",
    "h-s1-btn": "Prueba Gratuita",
    "h-s2-ph": "CON TASTY QR HACER PEDIDOS NUNCA FUE TAN FÁCIL",
    "h-s2-h1": "¿Que ofrecemos?",
    "h-s2-p": "Mejora la experiencia de tus clientes con las soluciones de pedidos de TastyQR. Permite a tus clientes ver tu menú de forma digital con tu marca y hacer pedidos online para evitar errores y ahorrar tiempo. Tu personal tendrá más tiempo para la hospitalidad. Además, TastyQR aumenta los montos de los pedidos mediante el avanzado sistema de Cross-Selling y Up-Selling.",
    "h-s2-btn": "Regístrate ahora",
    "h-s3-h2": "Descubre por qué TastyQR es excelente para tu negocio",
    "h-s3-p-p1": "",
    "h-s3-p-ps": "TastyQR",
    "h-s3-p-p3": "ofrece todas las funciones necesarias para administrar tu entorno de pedidos, desde pequeñas empresas hasta grandes empresas.",
    "h-s3-b1": "Cross-Selling y Up-Selling",
    "h-s3-b2": "Ahorra en costos de personal",
    "h-s3-b3": "Aumenta tu facturación",
    "h-s3-b4": "Integración con sistemas de punto de venta (POS)",
    "h-s3-b5": "Implementá tu propia identidad de marca",
    "h-s3-b6": "Informes y análisis avanzados",
    "h-s3-b7": "Personalizá fácilmente tu menú",
    "h-s3-btn": "Prueba Gratuita",
    "h-s4-ph": "No se necesita aplicación",
    "h-s4-h2": "Experimenta lo fácil que es",
    "h-s4-t1-h": "Escanear",
    "h-s4-t1-p": "Escanee el código QR con su teléfono y sea redirigido al menú con el número de mesa ya asignado.",
    "h-s4-t2-h": "Hacer pedido",
    "h-s4-t2-p": "Elija lo que desea comer, agregue una propina (opcional) y realice el pedido fácilmente pagando en línea o pagando manualmente después.",
    "h-s4-t3-h": "Hecho",
    "h-s4-t3-p": "Los detalles de cada pedido se envían automáticamente a tu personal para que puedan comenzar a preparar el pedido.",
    "h-s5-h2": "Características",
    "h-s5-p": "",
    "features-tab-1": "Menú",
    "features-tab-2": "Tablero",
    "features-tab-3": "Tableta",
    "h-s5-t1-h": "Pedido en línea",
    "h-s5-t1-p": "Aumenta las ventas de tu restaurante a medida que los clientes disfrutan de un proceso de pedido sin problemas, reduciendo los tiempos de espera y aumentando la eficiencia.",
    "h-s5-t2-h": "Pagos fáciles",
    "h-s5-t2-p": "Simplifica los pagos con nuestro sistema de pedidos en línea. Aprovecha la integración de Stripe y acepta pagos de manera fluida con compatibilidad de terminales.",
    "h-s5-t3-h": "Altamente personalizable",
    "h-s5-t3-p": "Toma el control de tu menú con nuestro sistema altamente personalizable. Actualiza y personaliza fácilmente tus productos para adaptarlos a tu marca única y visión culinaria.",
    "h-s5-t4-h": "Cross-Selling y Up-Selling",
    "h-s5-t4-p": "Aumenta tus ingresos con las funciones de Cross-Selling y Up-Selling de nuestro sistema, sugiriendo estratégicamente artículos adicionales durante el proceso de pago.",
    "h2-s5-t1-h": "Informes avanzados",
    "h2-s5-t1-p": "Obtén información valiosa con los informes avanzados de nuestro sistema, proporcionamos datos y análisis completos para optimizar tus estrategias comerciales.",
    "h2-s5-t2-h": "Gestiona tu restaurante fácilmente",
    "h2-s5-t2-p": "Maneja sin esfuerzo subusuarios, códigos QR, códigos de descuento, conexiones al sistema POS, métodos de pago, monedas de pago, personalización de menú y más.",
    "h2-s5-t3-h": "Conexiones al sistema POS",
    "h2-s5-t3-p": "Simplifica tus operaciones con nuestras conexiones al sistema POS. Conecta nuestro sistema QR a tu sistema POS para la integración automática de pedidos, la sincronización de productos y existencias en tiempo real.",
    "h2-s5-t4-h": "Gestiona los códigos QR",
    "h2-s5-t4-p": "Asigna fácilmente códigos QR a las mesas con nuestra práctica función, que permite a los usuarios generar y administrar códigos QR sin complicaciones.",
    "h3-s5-t1-h": "Gestiona los pedidos",
    "h3-s5-t1-p": "Después de que los clientes hagan su pedido a través del menú QR, el pedido aparecerá automáticamente en la tableta con toda la información necesaria para prepararlo.",
    "h3-s5-t2-h": "Gestiona los códigos QR",
    "h3-s5-t2-p": "La tableta te permite escanear nuevos códigos QR para asignarlos a una mesa o para desactivar temporalmente los códigos QR.",
    "h3-s5-t3-h": "Gestiona el inventario",
    "h3-s5-t3-p": "Administra fácilmente tu inventario con la tableta. Modifica los precios de tus productos, desactiva ciertos productos (debido a la falta de existencias) y más.",
    "h-s6-h2": "Descubre las posibilidades",
    "h-s6-p": "Eleva la experiencia de tu restaurante con herramientas innovadoras y posibilidades infinitas.",
    "h-s6-t1-h": "Aumentá las propinas",
    "h-s6-t1-p": "Aumenta la generosidad de tus clientes con una función integrada que les permite al cliente agregar propinas a sus pedidos y asi aumentar ganancias.",
    "h-s6-t2-h": "Pedido rápido y sin contacto",
    "h-s6-t2-p": "Permite a los clientes escanear el código QR y hacer pedidos directamente desde sus teléfonos, reduciendo los tiempos de espera.",
    "h-s6-t3-h": "Menú digital",
    "h-s6-t3-p": "Los clientes pueden escanear un QR para acceder al menú digital del restaurante. El menú incluye imágenes, descripciones y precios para cada plato.",
    "h-s6-t4-h": "Soporte multilingüe",
    "h-s6-t4-p": "Atiende a una diversa base de clientes con soporte multilingüe, asegurando que los clientes puedan ver y pedir en el menú en su idioma preferido.",
    "h-s6-t5-h": "Promociones y ofertas especiales",
    "h-s6-t5-p": "Muestra ofertas por tiempo limitado, descuentos o recompensas de lealtad para incentivar a tus clientes.",
    "h-s6-t6-h": "Análisis e informes",
    "h-s6-t6-p": "Obtén información sobre los patrones de pedido de los clientes, los platos más populares y el rendimiento de ventas para tomar decisiones basadas en datos.",
    "h-s7-h2": "Por qué los dueños de restaurantes eligen TastyQR",
    "h-s7-p": "Razones por las que TastyQR es valioso para tu negocio y por qué vale la pena la pequeña inversión. Moderniza tu restaurante con TastyQR y disfruta de las muchas ventajas que ofrecemos.",
    "h-s7-t1-h": "Aumenta tus ingresos",
    "h-s7-t1-b1": "Aumenta los ingresos",
    "h-s7-t1-b2": "Cross-Selling y Up-Selling",
    "h-s7-t1-b3": "Aumento de propinas",
    "h-s7-t2-h": "Reduce los costos operativos",
    "h-s7-t2-b1": "Tiempo de espera reducido",
    "h-s7-t2-b2": "Proceso eficiente de pedidos",
    "h-s7-t2-b3": "Menos personal requerido",
    "h-s7-t3-h": "Obtén más control",
    "h-s7-t3-b1": "Informes y análisis exhaustivos",
    "h-s7-t3-b2": "Fácil de gestionar y editar el menú",
    "h-s7-t3-b3": "Conoce a tus clientes",
    "h-s7-btn": "Comenzar",
    "h-s7-btn2": "Prueba Gratuita",
    "h-s7-link": "¿Alguna pregunta?",
    "h-s8-h2": "Lo que dicen nuestros clientes",
    "h-s8-p": "Lo que dicen nuestros clientes",
    "h-s8-t1-P": "“Denunciamos con indignación justa y desaprobamos a los hombres que están tan seducidos y encantados por los placeres del momento.”",
    "h-s8-t1-post": "Reportero",
    "h-s8-t2-P": "“Denunciamos con indignación justa y desaprobamos a los hombres que están tan seducidos y encantados por los placeres del momento.”",
    "h-s8-t2-post": "Reportero",
    "h-s8-t3-P": "“Denunciamos con indignación justa y desaprobamos a los hombres que están tan seducidos y encantados por los placeres del momento.”",
    "h-s8-t3-post": "Reportero",
    "h-s9-h2": "Algunos números que importan",
    "h-s9-p": "Hay una razón por la cual somos los mejores en el mercado.",
    "h-s9-b1": "Países",
    "h-s9-b2": "Clientes",
    "h-s9-b3": "Pedidos",
    "h-s9-b4": "Premio",
    "h-s10-h2": "¿Listo para explorar el futuro?",
    "h-s10-p": "Comienza gratis, sin necesidad de datos bancarios.",
    "h-s10-btn": "Prueba Gratuita",
    "i-s1-h2": "Integración perfecta con tu sistema POS",
    "i-s10-p": "Integraciones fáciles con tu POS para automatizar los pedidos y redirigir automáticamente los pedidos de TastyQR a tu sistema POS.",
    "i-s2-h2": "Integraciones de POS",
    "i-s3-h2": "Impresoras de recibos",
    "d-s1-h2": "Demostración de menú",
    "d-s1-p": "Experimenta cómo podría ser tu menú. Puedes personalizar tu menú para que se adapte al estilo de tu restaurante.",
    "d-t1-h": "1. Título aquí",
    "d-t2-h": "2. Título aquí",
    "d-t3-h": "3. Título aquí",
    "d-t4-h": "4. Título aquí",
    "d-t1-p": "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    "d-t2-p": "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    "d-t3-p": "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    "d-t4-p": "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    "f-h2": "Preguntas frecuentes",
    "f-p": "Si no encuentras respuestas a tus preguntas aquí, no dudes en preguntar. Nuestro equipo de atención al cliente está siempre listo para ayudarte.",
    "f1-h": "¿Cómo funciona la integración con el sistema POS?",
    "f2-h": "¿Cómo funciona TastyQR?",
    "f3-h": "¿Cuáles son los costos de transacción cuando los clientes pagan en línea?",
    "f4-h": "¿No se considera impersonal realizar pedidos mediante QR?",
    "f5-h": "¿Como pagan los clientes?",
    "f1-p": "Cuando es posible una integración con tu sistema POS, TastyQR funciona como una herramienta portátil para tus clientes. Los clientes pueden hacer pedidos por sí mismos, después de lo cual el pedido se agrega automáticamente a tu sistema POS. Con una integración con el sistema POS, también puedes importar y sincronizar productos, categorías, precios y más en el menú QR.",
    "f2-p": "Cada codigo QR está asignado a una mesa. Cuando un cliente escanea el QR y hace el pedido, TastyQR envía el pedido a una tablet junto a la caja registradora.",
    "f3-p": "iDEAL: €0,29. MasterCard/VISA (UE): €0,15 + 1,5%. MasterCard/VISA (No-UE/Empresa): €0,15 + 3,7%. AMEX (UE/No-UE/Empresa): €0,25 + 2,6%. Maestro: €0,40. Bancontact: €0,20",
    "f4-p": "En realidad, no lo es. Observamos que el pedido por QR brinda a los clientes una forma adicional de libertad si se utiliza de manera complementaria. Tus clientes tienen varias opciones para pedir una bebida como por ejemplo a través del personal o a través del menú en línea de TastyQR.",
    "f5-p": "Los clientes seguirán pagando como están acostumbrados. Al terminar de comer, piden la cuenta y los mozos cobran como normalmente lo hacen. En este proceso no cambia nada, actualmente se está trabajando para implementar pagos en línea mediante Mercado Pago.",
   // Página de precios
"p-s1-p": "Prueba TastyQR los primeros 30 días de forma gratuita. Después de eso, cobramos una tarifa fija.",

"p-monthly": "Mensual",
"p-yearly": "Anual",
"p-save": "Ahorra",

"p-month": "mes",
"p-billedAnnually": "Facturado anualmente",
"professional": "Personalizado",
"professional-start": "Contáctanos",

"p-s2-t1-ph": "Básico",
"p-s2-t2-ph": "Estándar",
"p-s2-t3-ph": "Profesional",

"p-s2-t1-p": "Recomendado para pequeñas empresas con menos de 20 mesas.",
"p-s2-t2-p": "Adecuado para cualquier tipo de negocio, la suscripción más elegida.",
"p-s2-t3-p": "Adecuado para cualquier tipo de negocio, hace que la experiencia sea aún más personal.",

"p-s2-b1": "Tablet incluida",
"p-s2-b2": "Pedidos ilimitados",
"p-s2-b3": "Pagos móviles en línea",
"p-s2-b4": "Mesas ilimitadas",
"p-s2-b5": "Los invitados pueden pedir en la cuenta",
"p-s2-b7": "Integracion de POS",
"p-s2-b8": "Plataforma para llevar y entrega",
"p-s2-b9": "Upselling",
"p-s2-b10": "Cross selling",
"p-s2-b11": "Integración de POS",
"p-s2-b12": "Ubicaciones adicionales:",
"p-s2-b13": "Marca:",
"p-cl": "Logo",
"p-color": "Colores personalizado",
"p-fc": "Totalmente personalizado",
"p-weeks": "Días",
"p-start": "Comienza ahora 30 días gratis",
"p-start2": "Comienza ahora",
"p-start3": "Contáctanos",
"compare": "Comparar",

"trial": "prueba",
"plan1-s2-b1": "Tablet incluida",
"plan1-s2-b2": "Pedidos ilimitados",
"plan1-s2-b3": "Mesas ilimitadas",
"plan1-s2-b4": "Diseño QR genérico",
"plan1-s2-b5": "Integración de POS",
"plan1-s2-b6": "Up/cross selling",

"plan2-s2-b1": "Tablet incluida",
"plan2-s2-b2": "Pedidos ilimitados",
"plan2-s2-b3": "Mesas ilimitadas",
"plan2-s2-b4": "QR personalizado",
"plan2-s2-b5": "Integración de POS",
"plan2-s2-b6": "Up/cross selling",

"plan3-s2-b1": "Tablet incluida",
"plan3-s2-b2": "Pedidos ilimitados",
"plan3-s2-b3": "Mesas ilimitadas",
"plan3-s2-b4": "QR personalizado",
"plan3-s2-b5": "Integración de POS",
"plan3-s2-b6": "Up/cross selling",

"location": "$79 al mes por ubicación",
"location-p": "Personalizado",

// Página Acerca de
'about_title': "Sobre nosotros",
'about_description': "¿Quiénes somos? Conócenos y descubre nuestra visión.",
"a-title": "TastyQR - Sobre Nosotros",
"a-s3-h1": "Nuestros Miembros del Equipo de Expertos",
"a-s3-p": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",

"a-s3-t1-post": "Diseñador de UI/UX",
"a-s3-t2-post": "Diseñador de UI/UX",
"a-s3-t3-post": "Diseñador de UI/UX",
"a-s3-btn": "Ver todos los equipos",
'about-label': "SOBRE NOSOTROS",
'about-head': "Bienvenido a TastyQR",
'about-text': "TastyQR, tu puerta de entrada a una nueva era de comodidad y eficiencia en el restaurante. Nos apasiona transformar la experiencia de los restaurantes a través de nuestro innovador sistema de pedido mediante códigos QR. En TastyQR, nuestra misión consiste en simplificar las operaciones de restaurantes y mejorar la experiencia del resturante y los clientes.",

'about-label2': "LO QUE NOS DESTACA",
'about-head2': "La Diferencia de TastyQR",
'about-text2': "En TastyQR, nos destacamos como pioneros en la industria de los sistemas de pedidos mediante códigos QR. Hemos aprovechado el poder de los códigos QR para ofrecer una solución de pedidos ágil, fácil de usar y sin contacto. Con solo escanear un código QR, los comensales pueden acceder a los menús de tu restaurante y realizar pedidos directamente desde sus propios celulares. Este enfoque innovador no solo mejora la experiencia a la hora de realizar pedidos, sino que también aumenta los ingresos y la para los dueños de restaurantes y disminuye la posibilidad de error por parte de los comenzales y mozos a la hora de realizar pedidos.",

'about-label3': "CÓMO SERÁ EL FUTURO",
'about-head3': "Nuestra Visión",
'about-text3': "Nuestra visión es estar a la vanguardia de la revolución digital que está sucediendo en los restaurantes, haciendo que cada visita a un restaurante sea una experiencia memorable. Creemos que la tecnología debe mejorar, la calidez y la hospitalidad de comer en restaurantes. TastyQR se compromete a acercar a los visitantes de restaurantes y a los propietarios en la era digital.",

    "about_title": "Acerca de nosotros",
    "about_description": "¿Quiénes somos? Conocé nuestra misión y visión.",
    "a-title": "TastyQR - Acerca de nosotros",
    "a-s3-h1": "Nuestro equipo de expertos",
    "a-s3-p": "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    "a-s3-t1-post": "Diseñador de interfaz de usuario/experiencia de usuario",
    "a-s3-t2-post": "Diseñador de interfaz de usuario/experiencia de usuario",
    "a-s3-t3-post": "Diseñador de interfaz de usuario/experiencia de usuario",
    "a-s3-btn": "Ver todos los equipos",

"c-s1-h1": "Ponte en contacto con nosotros",
"c-s1-p": "Puedes contactarnos por WhatsApp, Livechat o E-Mail y te ayudaremos lo antes posible.",

"c-s2--t1-h": "WhatsApp",
"c-s2--t2-h": "E-Mail",
"c-s2--t3-h": "Livechat",

"c-s2--t1-p": "Tiempo de respuesta: 3 horas",
"c-s2--t2-p": "Tiempo de respuesta: 24 horas",
"c-s2--t3-p": "Tiempo de respuesta: 6 horas",

"c-s2--t1-link": "Chatea ahora",
"c-s2--t2-link": "Enviar e-mail",
"c-s2--t3-link": "Chatea ahora",

"c-s2-h1": "Contáctanos",
"c-s2-b1": "Lunes - Viernes de 9am a 5pm PST",
"c-s2-b2": "support@Docmate.com",
"c-s2-b3": "+13-225-456-7890",
"c-s2-b4": "1901 Thornridge Cir. Shiloh, Hawaii 81063T",

"c-s2-l1": "Nombre *",
"c-s2-l2": "Nombre de la empresa",
"c-s2-l3": "E-mail *",
"c-s2-l4": "Mensaje *",
"c-s2-l5": "Mensaje",

"c-s2-p1": "Selecciona un tema",
"c-s2-p2": "Ingresa tu nombre aquí",
"c-s2-p3": "Ingresa el nombre de tu empresa aquí",
"c-s2-p4": "Ingresa tu e-mail aquí",
"c-s2-p5": "Ingresa tu mensaje aquí",
"contact": "Enviar",

    }
  },
  nl: {
    "translation": {
    "price_header": "Duidelijke prijzen, geen verborgen kosten.",
    "integration_header": "Naadloze integratie in uw bedrijf",
    "Home": "Home",
    "Integrations": "Integraties",
    "Demo": "Demo",
    "Price": "Prijzen",
    "About": "Over",
    "Contact": "Contact",
    "Sign-Up": "Probeer gratis",
    "h-s1-h1": "TastyQR,",
    "bottom-s2-h1": "de toekomst is hier",
    "h-s1-p": "Verbeter de efficiëntie en verbeter de klantervaring met QR-menu bestellen. Breng uw restaurant naar een hoger niveau: Digitaliseer menu's en automatiseer bestellingen.",
    "h-s1-btn": "Probeer gratis",
    "h-s2-ph": "SLIM QR BESTELSISTEEM",
    "h-s2-h1": "TastyQR Bestel- en betaaloplossingen",
    "h-s2-p": "Verbeter de ervaring van uw gasten met TastyQR besteloplossingen. Laat uw klanten uw eigen digitale menukaart bekijken, bestellingen plaatsen en betalingen uitvoeren om fouten te voorkomen en tijd te besparen. Uw personeel heeft meer tijd voor gastvrijheid. TastyQR verhoogt de bestelbedragen met slimme upselling en cross-selling.",
    "h-s2-btn": "Meld u nu aan",
    "h-s3-h2": "Waarom tastyQR?",
    "h-s3-p-p1": "Het",
    "h-s3-p-ps": "TastyQR",
    "h-s3-p-p3": "dashboard biedt alle functies die nodig zijn om uw bestelomgeving te beheren, van kleine bedrijven tot grote ondernemingen.",
    "h-s3-b1": "Consistente upselling en cross-selling",
    "h-s3-b2": "Bespaar op personeelskosten",
    "h-s3-b3": "Verhoog uw omzet",
    "h-s3-b4": "Integratie met kassasystemen",
    "h-s3-b5": "Implementeer uw eigen merkidentiteit",
    "h-s3-b6": "Uitgebreide inzichten en rapporten",
    "h-s3-b7": "Pas uw menu eenvoudig aan",
    "h-s3-btn": "Probeer gratis",
    "h-s4-ph": "Geen app nodig",
    "h-s4-h2": "Ervaar hoe eenvoudig het is",
    "h-s4-t1-h": "Scan",
    "h-s4-t1-p": "Scan de QR-code met hun telefoon en word automatisch doorgestuurd naar het menu met het tafelnummer al toegewezen.",
    "h-s4-t2-h": "Bestel",
    "h-s4-t2-p": "Kies wat u wilt eten, voeg een fooi toe en plaats de bestelling gemakkelijk online of betaal handmatig achteraf.",
    "h-s4-t3-h": "Klaar",
    "h-s4-t3-p": "De details van elke bestelling worden automatisch naar uw personeel gestuurd, zodat ze kunnen beginnen met de voorbereiding ervan.",
    "h-s5-h2": "Kenmerken",
    "h-s5-p": "",
    "features-tab-1": "Menu",
    "features-tab-2": "Dashboard",
    "features-tab-3": "Tablet",
    "h-s5-t1-h": "Online bestellen",
    "h-s5-t1-p": "Verhoog uw restaurantomzet doordat klanten genieten van een naadloos bestelproces, waardoor wachttijden worden verkort en de efficiëntie toeneemt.",
    "h-s5-t2-h": "Eenvoudige betalingen",
    "h-s5-t2-p": "Vereenvoudig betalingen met ons online bestelsysteem. Profiteer van Stripe-integratie en accepteer betalingen naadloos met compatibiliteit voor betaalterminals.",
    "h-s5-t3-h": "Hoog aanpasbaar",
    "h-s5-t3-p": "Neem de controle over uw menu met ons zeer aanpasbare systeem. Werk eenvoudig uw aanbiedingen bij en personaliseer ze om aan te sluiten bij uw unieke merk en culinaire visie.",
    "h-s5-t4-h": "Upselling en cross-selling",
    "h-s5-t4-p": "Verhoog uw omzet met de cross-selling en upselling functies van ons systeem, waarbij strategisch extra items worden voorgesteld tijdens het afrekenproces.",
    "h2-s5-t1-h": "Geavanceerde rapportage",
    "h2-s5-t1-p": "Krijg waardevolle inzichten met de geavanceerde rapportage van ons systeem, die uitgebreide gegevens en analyses biedt om uw bedrijfsstrategieën te optimaliseren.",
    "h2-s5-t2-h": "Beheer uw restaurant eenvoudig",
    "h2-s5-t2-p": "Beheer moeiteloos subgebruikers, QR-codes, kortingscodes, POS-verbindingen, betaalmethoden, valuta, menu-aanpassing en meer.",
    "h2-s5-t3-h": "POS-verbindingen",
    "h2-s5-t3-p": "Vergemakkelijk uw bedrijfsvoering met onze naadloze POS-verbindingen. Verbind ons QR-systeem met uw POS voor automatische orderintegratie en realtime product- en voorraadsynchronisatie.",
    "h2-s5-t4-h": "Beheer QR-codes",
    "h2-s5-t4-p": "Wijs moeiteloos QR-codes toe aan tafels met onze handige functie, waarmee gebruikers QR-codes probleemloos kunnen genereren en beheren.",
    "h3-s5-t1-h": "Beheer bestellingen",
    "h3-s5-t1-p": "Nadat gasten hun bestelling hebben geplaatst via het QR-menu, verschijnt de bestelling automatisch op de tablet met alle benodigde informatie om de bestelling voor te bereiden.",
    "h3-s5-t2-h": "Beheer QR-codes",
    "h3-s5-t2-p": "Met de tablet kunt u nieuwe QR-codes scannen om ze aan een tafel toe te wijzen of tijdelijk QR-codes te deactiveren.",
    "h3-s5-t3-h": "Voorraadbeheer",
    "h3-s5-t3-p": "Beheer uw voorraad eenvoudig met de tablet. Pas de prijzen van bepaalde producten aan, schakel bepaalde producten uit (wegens niet op voorraad), en meer.",
    "h-s6-h2": "Ontdek de mogelijkheden",
    "h-s6-p": "Verbeter uw restaurantervaring met innovatieve tools en eindeloze mogelijkheden.",
    "h-s6-t1-h": "Meer fooi",
    "h-s6-t1-p": "Moedig klantengenerositeit aan met een geïntegreerde functie waarmee klanten fooi aan hun bestellingen kunnen toevoegen, wat de verdiensten van uw personeel verhoogt.",
    "h-s6-t2-h": "Snelle en contactloze bestellingen",
    "h-s6-t2-p": "Sta klanten toe de QR-code te scannen en direct vanaf hun smartphone bestellingen te plaatsen, waardoor wachttijden worden verkort.",
    "h-s6-t3-h": "Naadloze integratie",
    "h-s6-t3-p": "Automatiseer orderverwerking door integratie met het kassasysteem zodat bestellingen via QR-codes automatisch naar uw kassasysteem worden doorgestuurd.",
    "h-s6-t4-h": "Meerdere talen",
    "h-s6-t4-p": "Bedien een diverse klantenkring met ondersteuning voor meerdere talen, zodat klanten het menu in hun voorkeurstaal kunnen bekijken.",
    "h-s6-t5-h": "Promoties en speciale aanbiedingen",
    "h-s6-t5-p": "Toon tijdelijke aanbiedingen, kortingen of loyaliteitsbeloningen om herhaalaankopen te stimuleren.",
    "h-s6-t6-h": "Analyse en rapportage",
    "h-s6-t6-p": "Krijg inzicht in bestelpatronen van klanten, populaire gerechten en verkoopprestaties om op data gebaseerde beslissingen te nemen.",
    "h-s7-h2": "Waarom operators kiezen voor TastyQR",
    "h-s7-p": "Redenen waarom TastyQR waardevol is voor uw bedrijf en waarom het de kleine investering waard is. Moderniseer uw restaurant met TastyQR en geniet van de vele voordelen die het biedt.",
    "h-s7-t1-h": "Verhoog uw omzet met meer dan 30%",
    "h-s7-t1-b1": "Verhoogde tafelomzet",
    "h-s7-t1-b2": "Upselling & cross-selling",
    "h-s7-t1-b3": "Verhoogde fooien",
    "h-s7-t2-h": "Verlaag de operationele kosten met 20%",
    "h-s7-t2-b1": "Verminderde wachttijden",
    "h-s7-t2-b2": "Efficiënte orderverwerking",
    "h-s7-t2-b3": "Minder personeel vereist",
    "h-s7-t3-h": "Meer controle over uw bedrijf",
    "h-s7-t3-b1": "Uitgebreide inzichten en rapporten",
    "h-s7-t3-b2": "Gemakkelijk te beheren en bewerken van het menu",
    "h-s7-t3-b3": "Leer uw klanten kennen",
    "h-s7-btn": "Aan de slag",
    "h-s7-btn2": "Probeer gratis",
    "h-s7-link": "Heeft u vragen?",
    "h-s8-h2": "Wat onze klanten zeggen",
    "h-s8-p": "Wat onze klanten zeggen",
    "h-s8-t1-P": "“We veroordelen met rechtvaardige verontwaardiging en hebben een hekel aan mannen die zo misleid zijn door de charmes van het genot van het moment.”",
    "h-s8-t1-post": "Verslaggever",
    "h-s8-t2-P": "“We veroordelen met rechtvaardige verontwaardiging en hebben een hekel aan mannen die zo misleid zijn door de charmes van het genot van het moment.”",
    "h-s8-t2-post": "Verslaggever",
    "h-s8-t3-P": "“We veroordelen met rechtvaardige verontwaardiging en hebben een hekel aan mannen die zo misleid zijn door de charmes van het genot van het moment.”",
    "h-s8-t3-post": "Verslaggever",
    "h-s9-h2": "Enkele belangrijke cijfers",
    "h-s9-p": "Er is een reden waarom we de beste in de markt zijn.",
    "h-s9-b1": "Landen",
    "h-s9-b2": "Klanten",
    "h-s9-b3": "Bestellingen",
    "h-s9-b4": "Onderscheiding",
    "h-s10-h2": "Klaar om te verkennen?",
    "h-s10-p": "Begin gratis, geen creditcard nodig.",
    "h-s10-btn": "Probeer gratis",
    "i-s1-h2": "Naadloze integratie met uw POS",
    "i-s10-p": "Eenvoudige integraties met uw POS om voorraad te automatiseren en bestellingen automatisch door te sturen van TastyQR naar uw POS-systeem.",
    "i-s2-h2": "POS-integraties",
    "i-s3-h2": "Bonprinters",
    "d-s1-h2": "Menu demo",
    "d-s1-p": "Ervaar hoe uw menu eruit zou kunnen zien. U kunt uw menu aanpassen om overeen te komen met de stijl van uw restaurant.",
    "d-t1-h": "1. Uw eigen logo",
    "d-t2-h": "2. Je eigen producten en categorieën",
    "d-t3-h": "3. Accepteer online bestellingen",
    "d-t4-h": "4. Laat fooi achter",
    "d-t1-p": "Upload uw logo en pas het aan met de kleuren van uw merk, waardoor de visuele aantrekkelijkheid van uw online menu wordt vergroot.",
    "d-t2-p": "Stel in en beheer eenvoudig uw producten en categorieën, inclusief vertalingen voor een ervaring op maat.",
    "d-t3-p": "Accepteer online bestellingen via uw menu, verminder het aantal fouten en verbeter de operationele efficiëntie.",
    "d-t4-p": "Voeg een gebruiksvriendelijke fooifunctie toe bij het afrekenen, zodat klanten moeiteloos hun dankbaarheid kunnen uiten.",
    "f-h2": "Veelgestelde vragen",
    "f-p": "Als u hier geen antwoorden op uw vragen vindt, aarzel dan niet om te vragen. Ons klantenserviceteam staat altijd klaar om te helpen.",
    "f1-h": "Hoe werkt een POS-integratie?",
    "f2-h": "Hoe werkt TastyQR zonder een POS-integratie?",
    "f3-h": "Wat zijn de transactiekosten wanneer gasten online betalen?",
    "f4-h": "Wordt QR-bestelling als onpersoonlijk beschouwd?",
    "f5-h": "Moeten gasten altijd direct betalen?",
    "f1-p": "Wanneer een integratie met uw POS mogelijk is, fungeert TastyQR eigenlijk als een handheld voor uw gasten. Gasten kunnen zelf bestellen, waarna de bestelling automatisch aan uw POS wordt toegevoegd. Met een POS-integratie kunt u ook producten, categorieën, prijzen en meer importeren en synchroniseren met het QR-menu.",
    "f2-p": "TastyQR werkt ook goed zonder een POS-integratie. In dat geval kan het restaurant ervoor kiezen om de bestellingen rechtstreeks naar hun bestaande printers te sturen. Als dit niet gewenst is, kunnen we de bestellingen ook binnen laten komen op een tablet naast de kassa.",
    "f3-p": "iDEAL: €0,29. MasterCard/VISA (EU): €0,15 + 1,5%. MasterCard/VISA (Non-EU/Business): €0,15 + 3,7%. AMEX (EU/Non-EU/Business): €0,25 + 2,6%. Maestro: €0,40. Bancontact: €0,20",
    "f4-p": "Dat is eigenlijk niet het geval. We zien dat QR-bestelling gasten een extra vorm van vrijheid geeft als QR-bestelling op een ondersteunende manier wordt gebruikt. Uw gasten hebben verschillende opties om een drankje te bestellen; via de bediening of via het online menu bijvoorbeeld.",
    "f5-p": "Dit is niet nodig bij TastyQR Standaard- en Professionele abonnementen. Met deze abonnementen kunt u ervoor kiezen om de gast te laten betalen met uw huidige terminal achteraf. Dit betekent dat de bestelling wordt geplaatst met behulp van de QR-code en uw ober daarna de terminal aan uw gast geeft om te betalen.",
    "p-s1-p": "Probeer TastyQR de eerste 30 dagen gratis. Daarna rekenen we een vaste vergoeding.",
    "p-monthly": "Maandelijks",
    "p-yearly": "Jaarlijks",
    "p-save": "Bespaar",
    "p-month": "maand",
    "p-billedAnnually": "Jaarlijks gefactureerd",
    "professional": "Op maat",
    "professional-start": "Neem contact op",
    "p-s2-t1-ph": "Starter",
    "p-s2-t2-ph": "Standaard",
    "p-s2-t3-ph": "Professioneel",
    "p-s2-t1-p": "Aanbevolen voor kleine bedrijven met minder dan 20 tafels.",
    "p-s2-t2-p": "Geschikt voor elk type bedrijf, de meest gekozen abonnement.",
    "p-s2-t3-p": "Geschikt voor elk type bedrijf, maak de ervaring nog persoonlijker.",
    "p-s2-b1": "iPad inbegrepen",
    "p-s2-b2": "Onbeperkte bestellingen",
    "p-s2-b3": "Online mobiele betalingen",
    "p-s2-b4": "Onbeperkte tafels",
    "p-s2-b5": "Gasten kunnen op rekening bestellen",
    "p-s2-b6": "POS-integratie",
    "p-s2-b7": "Up/cross selling",
    "p-s2-b8": "Afhaal- en bezorgplatform",
    "p-s2-b9": "Upselling",
    "p-s2-b10": "Cross-selling",
    "p-s2-b11": "Kassa-integratie",
    "p-s2-b12": "Extra locaties",
    "p-s2-b13": "Branding:",
    "p-cl": "Eigen logo",
    "p-color": "Kleuren",
    "p-fc": "Volledig op maat",
    "p-weeks": "Dagen",
    "p-start": "Begin nu",
    "p-start2": "Begin nu",
    "compare": "Vergelijk",
    "trial": "proefperiode",
    "plan1-s2-b1": "iPad inbegrepen",
    "plan1-s2-b2": "Onbeperkte bestellingen",
    "plan1-s2-b3": "Onbeperkte tafels",
    "plan1-s2-b4": "Basis sticker design",
    "plan1-s2-b5": "Up/cross selling",
    "plan1-s2-b6": "Kassa-integratie",
    "plan2-s2-b1": "iPad inbegrepen",
    "plan2-s2-b2": "Onbeperkte bestellingen",
    "plan2-s2-b3": "Onbeperkte tafels",
    "plan2-s2-b4": "Custom sticker branding",
    "plan2-s2-b5": "Up/cross selling",
    "plan2-s2-b6": "Kassa-integratie",
    "plan3-s2-b1": "iPad inbegrepen",
    "plan3-s2-b2": "Onbeperkte bestellingen",
    "plan3-s2-b3": "Onbeperkte tafels",
    "plan3-s2-b4": "Custom sticker branding",
    "plan3-s2-b5": "Up/Cross Selling",
    "plan3-s2-b6": "Kassa-integratie",
    "contact-h2": "Neem contact met ons op",
    "contact-h4": "Vul het onderstaande formulier in en we nemen zo spoedig mogelijk contact met u op.",
    "contact-name": "Naam",
    "contact-email": "E-mail",
    "contact-message": "Bericht",
    "contact-send": "Versturen",
    "contact-success": "Bedankt voor uw bericht! We nemen zo spoedig mogelijk contact met u op.",
    "contact-error": "Er is een fout opgetreden bij het verzenden van uw bericht. Probeer het alstublieft opnieuw.",
    "footer1": "Over",
    "footer2": "Integraties",
    "footer3": "Contact",
    "footer4": "Prijs",
    "footer5": "Demo",
    "footer6": "Privacybeleid",
    "footer7": "Gebruiksvoorwaarden",
    "footer8": "Veelgestelde vragen",
    "location": "€79 p/m per locatie",
"location-p": "Op maat",
"p-start3": "Contact",


// About Page
'about_title': "Over ons",
'about_description': "Wie zijn wij? Maak kennis met ons en kom meer te weten over onze missie en visie.",
"a-title": "TastyQR - Over ons",
"a-s3-h1": "Onze Teamleden",
"a-s3-p": "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",

"a-s3-t1-post": "UI/UX Designer",
"a-s3-t2-post": "UI/UX Designer",
"a-s3-t3-post": "UI/UX Designer",
"a-s3-btn": "Bekijk alle teams",

'about-label': "OVER ONS",
'about-head': "Welkom bij TastyQR",
'about-text': "TastyQR, jouw toegangspoort tot een nieuw tijdperk van eetgemak en efficiëntie. Wij zijn gepassioneerd over het transformeren van de restaurantervaring voor zowel gasten als restauranthouders via ons innovatieve QR-bestelsysteem. Met TastyQR.nl zijn we erop uit om restaurantoperaties te vereenvoudigen en de eetervaring te verbeteren, één QR-code per keer.",

'about-label2': "WAARIN WIJ UITBLINKEN",
'about-head2': "Het TastyQR Verschil",
'about-text2': "Bij TastyQR.nl onderscheiden we ons als pioniers in de branche van QR-bestelsystemen. We hebben de kracht van QR-codes benut om een gestroomlijnde, gebruiksvriendelijke en contactloze eetoplossing te bieden. Met slechts een scan van een QR-code kunnen gasten toegang krijgen tot menu's van restaurants, bestellingen plaatsen en betalingen doen rechtstreeks vanaf hun eigen smartphones. Deze innovatieve aanpak verbetert niet alleen de eetervaring, maar verhoogt ook de tafelomzet en de nauwkeurigheid van bestellingen voor restauranteigenaars.",

'about-label3': "HOE ZIET DE TOEKOMST ERUIT",
'about-head3': "Onze Visie",
'about-text3': "Onze visie is om voorop te lopen in de eetrevolutie en van elk restaurantbezoek een gedenkwaardige ervaring te maken. Wij geloven dat technologie de warmte en gastvrijheid van uit eten gaan moet verbeteren, niet vervangen. TastyQR.nl zet zich in om restaurantbezoekers en eigenaars dichter bij elkaar te brengen in het digitale tijdperk, terwijl de vreugde van gedeelde maaltijden en de authenticiteit van persoonlijke service behouden blijven.",

// Contact Page
"c-s1-h1": "Neem contact met ons op",
"c-s1-p": "Je kunt contact met ons opnemen via WhatsApp, Live Chat of e-mail en we zullen je zo snel mogelijk helpen.",

"c-s2--t1-h": "WhatsApp",
"c-s2--t2-h": "E-mailcontact",
"c-s2--t3-h": "Live chat",

"c-s2--t1-p": "Verwachte responstijd: 3 uur",
"c-s2--t2-p": "Verwachte responstijd: 24 uur",
"c-s2--t3-p": "Verwachte responstijd: 6 uur",

"c-s2--t1-link": "Chat nu",
"c-s2--t2-link": "E-mail sturen",
"c-s2--t3-link": "Chat nu",

"c-s2-h1": "Neem contact met ons op",
"c-s2-b1": "Maandag - vrijdag van 9.00 tot 17.00 uur PST",
"c-s2-b2": "support@Docmate.com",
"c-s2-b3": "+13-225-456-7890",
"c-s2-b4": "1901 Thornridge Cir. Shiloh, Hawaii 81063T",

"c-s2-l1": "Naam *",
"c-s2-l2": "Bedrijfsnaam",
"c-s2-l3": "E-mail *",
"c-s2-l4": "Bericht *",
"c-s2-l5": "Bericht",

"c-s2-p1": "Selecteer een onderwerp",
"c-s2-p2": "Voer hier je naam in",
"c-s2-p3": "Voer hier de naam van je bedrijf in",
"c-s2-p4": "Voer hier je e-mailadres in",
"c-s2-p5": "Voer hier je bericht in",
"contact": "Versturen",
    }
    }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;